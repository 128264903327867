import { TitleTemplate } from '../../components/title/c-title.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

export const defaultRefundConfirmationData = {
  title: '',
  confirm: '',
  decline: '',
  back: '',
  html: ''
}

export const RefundConfirmationTemplate = (d) => {
  d = { ...defaultRefundConfirmationData, ...d }
  return `
    <div>
      <div class="w-refund__body sp-stack-children--medium">
        ${d.back
          ? BtnTemplate({
            variant: 'flat-neutral',
            text: d.back,
            icon: 'chevron-left',
            extraClasses: 'w-refund__back-button',
            attributes: { 'data-w-refund__options-back': '' }
          })
          : ''}
        ${d.title
          ? TitleTemplate({
            text: d.title,
            size: 'default'
          })
          : ''}
        <div class="m-body">
          ${d.html}
        </div>
      </div>
      <div class="w-refund__buttons">
        ${d.confirm
          ? `${BtnTemplate({
            text: d.confirm,
            jsApi: true,
            variant: 'flat-neutral',
            attributes: { 'data-w-refund__options-confirm': '' }
          })}`
          : ''}
        ${d.decline
          ? `${BtnTemplate({
            text: d.decline,
            jsApi: true,
            attributes: { 'data-w-refund__options-decline': '' }
          })}`
          : ''}
      </div>
    </div>
    `
}
