export const da = {
  headerText: 'Vælg dine værelser',
  invalidRoomAllocation: 'Færre er blevet valgt i rummet end muligt. Det mindste antal mennesker er {MIN_ROOM_OCCUPANCY}. Ændr markeringen.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minAge} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minAge} to {maxAge} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(From {minAge} to {maxAge} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'Der er stadig {pendingPeople} uden plads tildelt. Venligst fordel dem',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'Der er ingen kompatible værelser til de mennesker, der afventer at distribuere. Fjernelse fra personer, der er tildelt i de foregående værelser, kan muligvis have tilgængelige værelser.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'Du skal vælge værelserne, inden du fortsætter',
  roomTypeDescriptionTitle: 'Værelse beskrivelse',
  specialAgeProfileHeaderTitle: 'Alderkategoriseringen er blevet opdateret automatisk for dem, der er på den aktuelle bolig',
  specialAgeProfileHeaderToggleText: 'vide mere',
  specialAgeProfileContentTitle: 'Visse overnatningssteder har deres egen alderskategorisering. Overvej aldringsklassificeringen for den aktuelle bolig er:'
}

export const de = {
  headerText: 'Wählen Sie Ihre Räume aus',
  invalidRoomAllocation: 'Es wurden weniger Personen im Raum ausgewählt als möglich. Die Mindestanzahl von Personen ist {MIN_ROOM_OCCUPANCY}. Ändern Sie die Auswahl.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minage} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minage} to {maxage} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(Until {maxage} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'Es sind noch {pendingPeople} ohne Zimmer zugewiesen. Bitte ordnen Sie sie zu',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'Es gibt keine kompatiblen Räume für die Personen, deren Verteilung ansteht. Durch das Entfernen von Personen, die in den vorherigen Räumen zugewiesen wurden, können möglicherweise verfügbare Räume verfügbar sein.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'Sie sollten die Räume auswählen, bevor Sie fortfahren',
  roomTypeDescriptionTitle: 'Zimmerbeschreibung',
  specialAgeProfileHeaderTitle: 'Die Alterskategorisierung wurde automatisch für die aktuelle Unterkunft aktualisiert',
  specialAgeProfileHeaderToggleText: 'mehr wissen',
  specialAgeProfileContentTitle: 'Bestimmte Unterkünfte haben ihre eigene Alterskategorie. Bitte beachten Sie, dass die Altersklassifizierung für die aktuelle Unterkunft wie folgt lautet:'
}

export const en = {
  headerText: 'Select your rooms',
  invalidRoomAllocation: 'Fewer people have been selected in the room than possible. The minimum number of people is {MIN_ROOM_OCCUPANCY}. Change the selection.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minage} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minage} to {maxage} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(Until {maxage} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'There are still {pendingPeople} without room assigned. Please, allocate them',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'There are no compatible rooms for the people pending to distribute. Removing from people allocated in the previous rooms may allow to have available rooms.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'You should select the rooms before continuing',
  roomTypeDescriptionTitle: 'Room description',
  specialAgeProfileHeaderTitle: 'The ages categorisation has been automatically updated for the ones on the current accommodation',
  specialAgeProfileHeaderToggleText: 'know more',
  specialAgeProfileContentTitle: 'Certain accommodations has teir own age caegorization, please consider the age classification for the current accommodation is:'
}

export const nb = {
  headerText: 'Velg rommene',
  invalidRoomAllocation: 'Færre er valgt ut i rommet enn mulig. Minste antall personer er {MIN_ROOM_OCCUPANCY}. Endre utvalget.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minage} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minage} to {maxage} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(Until {maxage} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'Det er fortsatt {PENDING_PEOPLE} uten plass tildelt. Vær så snill, tildel dem',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'Det er ingen kompatible rom for folk som venter på å distribuere. Å fjerne fra personer som er tildelt i de forrige rommene, kan tillate å ha tilgjengelige rom.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'Du bør velge rom før du fortsetter',
  roomTypeDescriptionTitle: 'Rombeskrivelse',
  specialAgeProfileHeaderTitle: 'Alderkategoriseringen er automatisk oppdatert for de som er på gjeldende innkvartering',
  specialAgeProfileHeaderToggleText: 'vite mer',
  specialAgeProfileContentTitle: 'Enkelte overnattingssteder har sin egen alderskategori. Vennligst vurder aldersklassifiseringen for gjeldende innkvartering:'
}

export const fr = {
  headerText: 'Sélectionnez vos chambres',
  invalidRoomAllocation: 'Moins de personnes ont été sélectionnées dans la salle que possible. Le nombre minimum de personnes est {MIN_ROOM_OCCUPANCY}. Changer la sélection.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minage} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minage} to {maxage} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(Until {maxage} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'Il y a encore {pendingPeople} sans chambre assignée. Veuillez les attribuer',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'Il n\'y a pas de salles compatibles pour les personnes en attente de distribution. Le retrait des personnes attribuées dans les chambres précédentes peut permettre d\'avoir des chambres disponibles.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'Vous devez sélectionner les salles avant de continuer',
  roomTypeDescriptionTitle: 'Description de la pièce',
  specialAgeProfileHeaderTitle: 'La catégorisation des âges a été automatiquement mise à jour pour celles du logement actuel',
  specialAgeProfileHeaderToggleText: 'savoir plus',
  specialAgeProfileContentTitle: 'Certains hébergements ont leur propre catégorie d\'âge, veuillez considérer que la classification par âge du logement actuel est:'
}

export const nl = {
  headerText: 'Selecteer uw kamers',
  invalidRoomAllocation: 'Er zijn minder mensen in de kamer geselecteerd dan mogelijk. Het minimum aantal personen is {MIN_ROOM_OCCUPANCY}. Wijzig de selectie.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minage} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minage} to {maxage} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(Until {maxage} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'Er zijn nog steeds {pendingPeople} zonder toegewezen kamer. Gelieve hen toe te wijzen',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'Er zijn geen compatibele kamers voor de mensen die in afwachting zijn om te verdelen. Verwijderen van personen die in de vorige kamers zijn toegewezen, kan ervoor zorgen dat er beschikbare kamers beschikbaar zijn.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'U moet de kamers selecteren voordat u verder gaat',
  roomTypeDescriptionTitle: 'Kamer beschrijving',
  specialAgeProfileHeaderTitle: 'De leeftijdscategorisatie is automatisch bijgewerkt voor die van de huidige accommodatie',
  specialAgeProfileHeaderToggleText: 'meer weten',
  specialAgeProfileContentTitle: 'Bepaalde accommodaties hebben hun eigen leeftijdscategorie, houd er rekening mee dat de leeftijdsclassificatie voor de huidige accommodatie is:'

}

export const sv = {
  headerText: 'Välj dina rum',
  invalidRoomAllocation: 'Färre har valts ut i rummet än möjligt. Minsta antal personer är {MIN_ROOM_OCCUPANCY}. Ändra markeringen.',
  adultsWord: 'Adults',
  adultWord: 'Adult',
  adultsAgeRange: '(From {minage} years old)',
  childrenWord: 'Children',
  childWord: 'Child',
  childrenAgeRange: '(From {minage} to {maxage} years old)',
  babiesWord: 'Babies',
  babyWord: 'Baby',
  babiesAgeRange: '(Until {maxage} years old)',
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available',
  moreBabiesThanAdults: 'You can\'t allocate in a room more babies than adults',
  peoplePendingToAllocateText: 'Det finns fortfarande {pendingPeople} utan tilldelat rum. Snälla, fördela dem',
  peopleAllocatedInExcess: '{PARTICIPANTS_NUM} {AGE_PROFILE_NAME} are assigned in excess. Please, move them to another room',
  noCompatibleRoomsForPeoplePendingToAllocateText: 'Det finns inga kompatibla rum för personer som väntar på att distribuera. Att ta bort från personer tilldelade i de tidigare rummen kan göra det möjligt att ha tillgängliga rum.',
  wrongDistributionInRooms: 'The participants distribution in a room is wrong. Please check it all',
  pricePerPerson: 'price per person',
  pricePerPersonAbbreviation: 'p.p.',
  roomWord: 'Room',
  noValidDistributionInRoom: 'Current distribution it´is not valid for this room, please allocate travellers differently',
  noMoreRoomAvailable: 'No rooms available, try a different combination of rooms',
  wrongAllocationMessageToContinue: 'Du bör välja rum innan du fortsätter',
  roomTypeDescriptionTitle: 'Rumsbeskrivning',
  specialAgeProfileHeaderTitle: 'Åldersgruppskategoriseringen har automatiskt uppdaterats för de som finns på det aktuella boendet',
  specialAgeProfileHeaderToggleText: 'veta mer',
  specialAgeProfileContentTitle: 'Vissa boenden har sin egen åldersindelning, tänk på att åldersklassificeringen för det aktuella boendet är:'
}
