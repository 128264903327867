export const da = {
  selectRoomButtonText: 'Vælg værelse',
  onRequestLabel: 'På anmodning',
  roomTypeDescriptionTitle: 'Værelse beskrivelse',
  emptyFilteredRooms: 'Der er ingen værelser, der matcher din belægning. Skift belægning, eller lad det hele være nul for ikke at filtrere.',
  roomsNotMatchingOccupancyHeader: 'Der er {n} værelser, der ikke kan bruges med den valgte belægning:',
  priceFromWord: 'fra'
}

export const de = {
  selectRoomButtonText: 'Raum auswählen',
  onRequestLabel: 'Auf Anfrage',
  roomTypeDescriptionTitle: 'Zimmerbeschreibung',
  emptyFilteredRooms: 'Es gibt keine Zimmer, die Ihrer Belegung entsprechen. Bitte ändern Sie die Belegung oder lassen Sie alles auf Null, um nicht zu filtern.',
  roomsNotMatchingOccupancyHeader: 'Es gibt {n} Räume, die bei ausgewählter Belegung nicht genutzt werden können:',
  priceFromWord: 'ab'
}

export const en = {
  selectRoomButtonText: 'Select room',
  onRequestLabel: 'On request',
  roomTypeDescriptionTitle: 'Room description',
  emptyFilteredRooms: 'There are no rooms matching your occupancy. Please change the occupancy or leave it all to zero to not filter.',
  roomsNotMatchingOccupancyHeader: 'There are {n} rooms that cannot be used with selected occupancy:',
  priceFromWord: 'from'
}

export const nb = {
  selectRoomButtonText: 'Velg rom',
  onRequestLabel: 'På forespørsel',
  roomTypeDescriptionTitle: 'Rombeskrivelse',
  emptyFilteredRooms: 'Det er ingen rom som passer til ditt belegg. Vennligst endre belegget eller la alt være på null for ikke å filtrere.',
  roomsNotMatchingOccupancyHeader: 'Det er {n} rom som ikke kan brukes med valgt belegg:',
  priceFromWord: 'fra'
}

export const fr = {
  selectRoomButtonText: 'Sélectionnez la chambre',
  onRequestLabel: 'Sur demande',
  roomTypeDescriptionTitle: 'Description de la pièce',
  emptyFilteredRooms: 'Aucune chambre ne correspond à votre occupation. Veuillez modifier l\'occupation ou laisser le tout à zéro pour ne pas filtrer.',
  roomsNotMatchingOccupancyHeader: 'Il y a {n} chambres qui ne peuvent pas être utilisées avec l\'occupation sélectionnée:',
  priceFromWord: 'à partir de'
}

export const nl = {
  selectRoomButtonText: 'Selecteer kamer',
  onRequestLabel: 'Op verzoek',
  roomTypeDescriptionTitle: 'Kamer beschrijving',
  emptyFilteredRooms: 'Er zijn geen kamers die overeenkomen met uw bezetting. Wijzig de bezetting of laat alles op nul staan om niet te filteren.',
  roomsNotMatchingOccupancyHeader: 'Er zijn {n} kamers die niet kunnen worden gebruikt met de geselecteerde bezetting:',
  priceFromWord: 'vanaf'
}

export const sv = {
  selectRoomButtonText: 'Välj rum',
  onRequestLabel: 'På förfrågan',
  roomTypeDescriptionTitle: 'Rumsbeskrivning',
  emptyFilteredRooms: 'Det finns inga rum som matchar ditt boende. Ändra beläggningen eller lämna allt till noll för att inte filtrera.',
  roomsNotMatchingOccupancyHeader: 'Det finns {n} rum som inte kan användas med vald beläggning:',
  priceFromWord: 'från'
}
