import { registerWidget } from '../../../js/core/widget/widget-directory'
import { VoucherCheckBalanceInfoTemplate } from './w-voucher-check-balance.info.template'
import Voucher from '../voucher/main'
import Component from '../../../js/core/component/component'
import { elementFromString, flush } from '../../../js/document/html-helper'

const widgetApi = 'w-voucher-check-balance'

export const attr = {
  enableRefund: `data-${widgetApi}__enable-refund`,
  refundRequestUrl: `data-${widgetApi}__refund-request-url`,
  refundShowAllCountries: `data-${widgetApi}__refund-show-all-countries`,
  refundTrack: `data-${widgetApi}__refund-track`
}

const widgetQueries = {
  voucherRedeemer: `[data-${widgetApi}__voucher-redeemer]`,
  voucher: `[data-${widgetApi}__voucher]`,
  content: `[data-${widgetApi}__content]`
}

export default class VoucherCheckBalance {
  /**
   * Creates a new Voucher Check Balance
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach Voucher Check Balance
   * @param options
   */
  constructor (element, options = {}) {
    this.element = element
    this.element[widgetApi] = this
    this.locales = this.getLocales()
    this._getHtmlElements()
    this._attachEvents()
  }

  _getHtmlElements () {
    this.enableRefund = this.element.getAttribute(attr.enableRefund)
    this.contentElement = this.element.querySelector(widgetQueries.content)
    const voucherRedeemer = this.element.querySelector(widgetQueries.voucherRedeemer)
    if (voucherRedeemer) {
      this.voucherRedeemerApi = voucherRedeemer['w-voucher-redeemer']
    }
  }

  _attachEvents () {
    this.voucherRedeemerApi && this.voucherRedeemerApi.events.on('voucherFetched', this._render, this)
  }

  _render (data) {
    const isExpired = !data.isActive || !data.canBeUsed
    const html = isExpired ? this._renderExpired() : this._renderBalance(data)
    this.voucherRedeemerApi.destroy()
    const newContent = elementFromString(html)
    Component.initDocumentComponentsFromAPI(newContent)
    Component.initComponentActionElements(newContent)
    flush(this.contentElement)
    this.contentElement.appendChild(newContent)
    const voucherElement = this.contentElement.querySelector(widgetQueries.voucher)
    if (voucherElement) {
      this.voucherApis = []
      const voucherApi = new Voucher(voucherElement, {
        data: {
          id: `${this.element.id}Voucher`,
          price: {
            ...(data.amount && data.amount.value) && { value: data.amount.value },
            currencyPosition: data.amount.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
            ...(data.amount && data.amount.currencySettings && data.amount.currencySettings.symbol) && { currency: data.amount.currencySettings.symbol }
          },
          bonus: {
            ...(data.bonus && data.bonus.amountLabel) && { amountLabel: data.bonus.amountLabel },
            ...(data.bonus && data.bonus.bonusLabel) && { bonusLabel: data.bonus.bonusLabel }
          },
          attributes: {
            'data-w-voucher__refund-extra-param-voucher-code': data.voucherCode,
            'data-w-voucher__refund-extra-param-email': data.email,
            'data-w-voucher__refund-extra-param-birthdate': data.birthdate
          },
          refundRequestUrl: this.element.getAttribute(attr.refundRequestUrl),
          showAllCountries: this.element.getAttribute(attr.refundShowAllCountries) !== null,
          refundId: `${this.element.id}Refund`,
          refundTrack: this.element.getAttribute(attr.refundTrack),
          enableRefund: this.enableRefund != null,
          canRequestRefund: data.refund && data.refund.canRequestRefund,
          isAlreadyRefunded: data.refund && data.refund.isAlreadyRefunded,
          isAlreadyRequestedAndPendingToBeRefunded: data.refund && data.refund.isAlreadyRequestedAndPendingToBeRefunded,
          ...this.locales.balanceAmountText && { description: this.locales.balanceAmountText },
          ...this.locales.balanceExtraText && { expirationText: this.locales.balanceExtraText.replace('{EXPIRATION_DATE}', data.expirationDate) }
        }
      })
      this.voucherApis.push(voucherApi)
    }
  }

  _renderExpired () {
    return VoucherCheckBalanceInfoTemplate({
      isExpired: true,
      ...this.locales.expiredIcon && { expiredIcon: this.locales.expiredIcon },
      ...this.locales.expiredText && { expiredText: this.locales.expiredText },
      ...this.locales.expiredExtraText && { expiredExtraText: this.locales.expiredExtraText }
    })
  }

  _renderBalance (data) {
    return VoucherCheckBalanceInfoTemplate({
      isExpired: false,
      voucherCode: data.voucherCode
    })
  }

  getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }
}

registerWidget(VoucherCheckBalance, widgetApi)
