export const da = {
  noComment: 'Brugeren har ikke efterladt nogen kommentarer',
  translateButton: 'Oversæt anmeldelse',
  viewOriginalButton: 'Vis oprindelig anmeldelse'
}

export const de = {
  noComment: 'Der Benutzer hat keine Kommentare hinterlassen',
  translateButton: 'Übersetzen Sie diese Bewertung',
  viewOriginalButton: 'Original text zeigen'
}

export const en = {
  noComment: 'The user has left no comments',
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text'
}

export const fr = {
  noComment: "L'utilisateur n'a laissé aucun commentaire",
  translateButton: 'Traduisez cette avis',
  viewOriginalButton: 'Voir original review'
}

export const nl = {
  noComment: 'De gebruiker heeft geen opmerkingen achtergelaten',
  translateButton: 'Vertaal deze review',
  viewOriginalButton: 'Toon originele review'
}

export const sv = {
  noComment: 'Användaren har inte lämnat några kommentarer',
  translateButton: 'Översätt recensionen',
  viewOriginalButton: 'Visa original texten'
}
