import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import { ReviewsListItemTemplate } from './w-reviews-list-item.template'
import Component from '../../../js/core/component/component'
import { reviewsListEvents } from '../../../js/document/event-types'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import registeredEvents from '../../../js/helpers/registered-events'
import { DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { apiCaller } from '../../../js/helpers/api-caller'
import webStorage from '../../../js/document/web-storage'
import { reviewsListType as events } from '../data-layer/types'

require('../../components/collapse/main')
require('../../components/pagination/main')
require('../../components/progress-bar/main')

const EventEmitter = require('eventemitter3')
const widgetApi = 'w-reviews-list'
const dropdownMultipleWidgetApi = 'c-dropdown-multiple'
const dropdowntApi = 'c-dropdown'

const scrollConfig = {
  block: 'start',
  behavior: 'smooth'
}

const translationState = {
  translated: 'translated',
  original: 'original'
}

export const attr = {
  extraParams: 'input[type="hidden"]',
  track: 'data-track',
  isInsideModal: 'is-inside-modal'
}

const widgetQueries = {
  itemElement: `data-${widgetApi}__item`,
  itemsElement: `data-${widgetApi}__items`,
  itemTextElement: `data-${widgetApi}__item-text`,
  noItemsArea: `data-${widgetApi}__no-items-area`,
  paginationElement: `data-${widgetApi}__pagination`,
  reviewCountryFlag: `data-${widgetApi}__country-flag`,
  reviewOverallRating: `data-${widgetApi}__main-rating`,
  reviewsOverallTitle: `data-${widgetApi}__title`,
  reviewPartyComp: `data-${widgetApi}__item-subtitle`,
  sortingElement: `data-${widgetApi}__sorting`,
  translateButton: `data-${widgetApi}__comment-translated-button-translate`,
  translatedTextArea: `data-${widgetApi}__comment-translate-text`,
  travellerFilterElementParent: `data-${widgetApi}__filters-area--typetraveler`,
  typeTravellerFilter: `data-${widgetApi}__typetraveller-dropdown`,
  viewOriginalTextButton: `data-${widgetApi}__comment-translated-button-view-original`,
  translateUrl: `data-${widgetApi}__translate-url`,
  url: `data-${widgetApi}__url`,
  method: `data-${widgetApi}__method`,
  showPartyComposition: `data-${widgetApi}__show-party-composition`,
  showScores: `data-${widgetApi}__show-scores`
}

const classNames = {
  empty: 'empty',
  hasData: 'has-data',
  in: 'in',
  isLoading: 'is-loading',
  isHidden: 'is-hidden',
  isTransalted: 'is-translated',
  languageSwitch: `.${widgetApi}__filters-area--language-switch`,
  loaderIcon: `.${widgetApi}__comment-translate-loader`,
  noReviews: 'no-reviews',
  notLoaded: 'not-loaded',
  reviewSingleScore: `.${widgetApi}__item-score`,
  reviewsLoaderIcon: `.${widgetApi}__loader`,
  scoreGrade: '.c-number-score__grade',
  scoreTitle: '.c-number-score__title'
}

export default class ReviewsList {
  /**
   * Creates a new ReviewsList
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   */
  constructor (element) {
    this.element = element
    this.elementId = this.element.id
    this.itemsElement = this.element.querySelector(`[${widgetQueries.itemsElement}]`)
    this.sortingElement = this.element.querySelector(`[${widgetQueries.sortingElement}]`)[dropdowntApi]
    this.travellerFilterElement = this.element.querySelector(`[${widgetQueries.typeTravellerFilter}]`)
    this.travellerFilterElementParent = this.element.querySelector(`[${widgetQueries.travellerFilterElementParent}]`)
    this.languageSwitchParent = this.element.querySelector(classNames.languageSwitch)
    if (this.languageSwitchParent) { this.languageSwitch = this.languageSwitchParent.querySelector('input') }
    this.url = this.element.getAttribute(widgetQueries.url)
    this.extraParams = this.getExtraParameters()
    this.extraParamsNoLimit = this.getExtraParametersNoLimit()
    this.showScores = this.element.hasAttribute(widgetQueries.showScores)
    this.componentInsideModal = this.element.hasAttribute(attr.isInsideModal)
    this.showPartyComposition = this.element.hasAttribute(widgetQueries.showPartyComposition)
    this.noItemsArea = this.element.querySelector(`[${widgetQueries.noItemsArea}]`)
    this.reviewsLoaderIcon = this.element.querySelector(classNames.reviewsLoaderIcon)
    this.reviewsOverallTitle = this.element.querySelector(`[${widgetQueries.reviewsOverallTitle}]`)
    this.overallTitleText = this.reviewsOverallTitle.innerHTML
    this.options = {
      method: this.element.getAttribute(widgetQueries.method),
      url: this.element.getAttribute(widgetQueries.translateUrl)
    }

    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
    const paginationOffset = 'offset' in this.extraParams ? this.extraParams.offset : 0
    const paginationLimit = 'limit' in this.extraParams ? this.extraParams.limit : 10
    this.pagination = {
      element: this.element.querySelector(`[${widgetQueries.paginationElement}]`),
      limit: paginationLimit,
      offset: paginationOffset,
      currentPage: 1,
      totalPages: null
    }

    this.childComponents = {
      pagination: this.pagination.element ? this.pagination.element['c-pagination'] : null
    }

    this.bindEvents()

    if (!this.componentInsideModal) {
      this.fetch()
    }

    this.element[widgetApi] = {
      events: this.events,
      fetch: this.fetch.bind(this)
    }

    this.globalLocales = register(`window.sundio.i18n.${language}.global`)
    this.componentLocales = register(`window.sundio.i18n.${language}.reviewsList`)
    this.customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.elementId}"]`)
    try {
      this.customLocaleData = JSON.parse(this.customLocaleElement.textContent)
    } catch (err) { }
    this.staticTexts = { ...this.globalLocales, ...this.componentLocales, ...(this.customLocaleData || {}) }

    this.sortingElement.events.on('change', e => {
      this.updateResults()
    })
    if (this.languageSwitch) {
      this.languageSwitch.addEventListener('change', e => {
        this.events.emit(reviewsListEvents.REVIEWS_LIST_LANGUAGE_SWITCH, { status: this.languageSwitch.checked })
        this.updateResults()
      })
    }
  }

  /**
   * get All Option is-selected inside filters dropdown
   */
  getOptionsSelected (dropdownElement) {
    return dropdownElement[dropdownMultipleWidgetApi].getSelectedValues()
  }

  /**
   * resolves the promise and sets travelFilter var
   */
  async resolveSetTravelFilterDropdownPromise (reviewsAmountOfTravelerTypes) {
    if (!this.travellerFilterElement) {
      return
    }
    this.travelFilter = await this.setTravelFilterDropdown(reviewsAmountOfTravelerTypes)
  }

  /**
   * set Party comp total amount for the travel filters dropdown and build the dropdown itself
   */
  async setTravelFilterDropdown (reviewsAmountOfTravelerTypes) {
    try {
      const partyCompGroup = []
      const selectedOptions = this.travelFilter ? this.getOptionsSelected(this.travelFilter) : []
      reviewsAmountOfTravelerTypes.forEach(group => {
        let partyCompositionText = ''
        const partyCompositionValue = group.travelerTypeId.toString()
        if (this.staticTexts[partyCompositionValue]) {
          partyCompositionText = this.staticTexts[partyCompositionValue]
        } else {
          partyCompositionText = this.staticTexts[group.travelerTypeName.toLowerCase()]
        }

        if (partyCompositionText) {
          partyCompGroup.push(
            {
              caption: partyCompositionText,
              count: group.amount,
              isSelected: selectedOptions.indexOf(partyCompositionValue) > -1,
              isAvailable: true,
              value: partyCompositionValue
            })
        }
      })

      const filterLabel = this.travellerFilterElement[dropdownMultipleWidgetApi].getLabel()
      const filterOkButtonText = this.travellerFilterElement[dropdownMultipleWidgetApi].getOkButtonLabelText()
      const filterCancelButtonText = this.travellerFilterElement[dropdownMultipleWidgetApi].getCancelButtonLabelText()
      const filterData = {
        id: this.elementId + '-party-comp-filter',
        isMultiple: true,
        variant: 'compact',
        label: filterLabel,
        icon: '1-person',
        extraClasses: this.travellerFilterElement.classList,
        okButtonText: filterOkButtonText,
        cancelButtonText: filterCancelButtonText,
        track: events.PARTY_COMPOSITION_FILTER,
        attributes: { [widgetQueries.typeTravellerFilter]: '' },
        options: partyCompGroup.map(filter => ({
          selected: filter.isSelected,
          disable: filter.isAvailable,
          text: filter.caption,
          value: filter.value,
          count: filter.count
        }))
      }

      this.travellerFilterElementParent.innerHTML = DropdownTemplate(filterData)
      this.travellerFilterElement = this.element.querySelector(`[${widgetQueries.typeTravellerFilter}]`)

      Component.initDocumentComponentsFromAPI(this.travellerFilterElement)

      this.dropdownFilterToFillButtonOk = this.travellerFilterElement[dropdownMultipleWidgetApi].getOkButton()
      this.dropdownFilterToFillButtonOk.addEventListener('click', () => {
        this.updateResults()
      })

      return this.travellerFilterElement
    } catch (e) {
      if (window.newrelic) {
        window.newrelic.noticeError('[ReviewsList] Error with Filters:' + e)
      }
    }
  }

  /**
   * Update results when sorting or Filtering
   */
  updateResults () {
    this.extraParams = this.getExtraParameters()
    this.pagination.offset = 0
    this.bindEvents()

    this.fetch()
    this.setTranslateListeners()
  }

  /**
   * Sets listeners for translate button
   */
  setTranslateListeners () {
    const updatedItemsElement = this.element.querySelectorAll(`[${widgetQueries.itemElement}]`)
    updatedItemsElement.forEach(item => {
      if (item.querySelector(`[${widgetQueries.translateButton}]`)) {
        item.querySelector(`[${widgetQueries.translateButton}]`).addEventListener('click', () => this.translateReview(item))
        item.querySelector(`[${widgetQueries.viewOriginalTextButton}]`).addEventListener('click', () => this.viewOriginalTextReview(item))
      }
    })
  }

  /**
   * view original review
   *
   * @param {Object} review  - Review item that is going to turn back to show original text
   */
  viewOriginalTextReview (review) {
    const translatedTextContainer = review.querySelector(`[${widgetQueries.translatedTextArea}]`)
    const translateButton = review.querySelector(`[${widgetQueries.translateButton}]`)
    const viewOriginalTextButton = review.querySelector(`[${widgetQueries.viewOriginalTextButton}]`)

    review.classList.remove(classNames.isTransalted)
    translatedTextContainer.innerText = ''
    translatedTextContainer.classList.add(classNames.empty)
    translateButton.classList.remove(classNames.isHidden)
    translatedTextContainer.classList.add(classNames.isHidden)
    viewOriginalTextButton.classList.add(classNames.isHidden)

    this.translateDataLayer(review, translationState.original)
  }

  /**
   * Translate the review
   *
   * @param {Object} review  - Review item that is going to be translated
   */
  async translateReview (review) {
    const reviewId = review.getAttribute('id')
    const loaderIcon = review.querySelector(classNames.loaderIcon)
    const translatedTextContainer = review.querySelector(`[${widgetQueries.translatedTextArea}]`)
    const translateButton = review.querySelector(`[${widgetQueries.translateButton}]`)
    const viewOriginalTextButton = review.querySelector(`[${widgetQueries.viewOriginalTextButton}]`)

    loaderIcon.classList.remove(classNames.isHidden)
    const originalText = review.querySelector(`[${widgetQueries.itemTextElement}]`).querySelector('.m-body').innerHTML

    const translationId = reviewId + '-' + language
    const storagedTranslation = webStorage.local.get(translationId)

    // first we check if the translate exist in the localstorage, if not, we proced with the translations
    try {
      let translatedText = ''
      if (storagedTranslation) {
        translatedText = storagedTranslation
      } else {
        const data = { text: originalText }
        const result = await apiCaller(this.options.url, { body: data, method: this.options.method })

        if (result.success) {
          translatedText = result.response.text
          webStorage.local.set(translationId, translatedText)
        }
      }

      if (translatedText !== '') {
        review.classList.add(classNames.isTransalted)
        translatedTextContainer.innerText = translatedText
        translatedTextContainer.classList.remove(classNames.empty)
        translateButton.classList.add(classNames.isHidden)
        loaderIcon.classList.add(classNames.isHidden)
        viewOriginalTextButton.classList.remove(classNames.isHidden)
      } else {
        loaderIcon.classList.add(classNames.isHidden)
      }
    } catch (e) {
      if (window.newrelic) {
        window.newrelic.noticeError('[ReviewsList] Error translating:' + e)
      }
    }

    this.translateDataLayer(review, translationState.translated)
  }

  /**
   * Pushes the review data to datalayer
   *
   * @param {Object} review     - Review item that is going to be translated or get back to original text
   * @param {String} status     - status of the translation text
   */
  translateDataLayer (review, status) {
    const reviewId = review.getAttribute('id')
    const reviewOverallRatingElement = review.querySelector(`[${widgetQueries.reviewOverallRating}]`)
    const overallRating = (reviewOverallRatingElement && reviewOverallRatingElement.querySelector(classNames.scoreGrade)) ? parseInt(reviewOverallRatingElement.querySelector(classNames.scoreGrade).innerHTML) : null
    const country = review.querySelector(`[${widgetQueries.reviewCountryFlag}]`) ? review.querySelector(`[${widgetQueries.reviewCountryFlag}]`).getAttribute('data-m-country-flag__country-id') : null
    const partyComp = review.querySelector(`[${widgetQueries.reviewPartyComp}]`) ? review.querySelector(`[${widgetQueries.reviewPartyComp}]`).innerHTML : null
    const categoryScores = []
    const categoryScoresItem = review.querySelectorAll(classNames.reviewSingleScore)

    categoryScoresItem.forEach(item => {
      const itemScore = item.querySelector(classNames.scoreGrade) ? parseInt(item.querySelector(classNames.scoreGrade).innerHTML) : null
      const itemLabel = item.querySelector(classNames.scoreTitle) ? item.querySelector(classNames.scoreTitle).innerHTML : null

      if (itemScore) {
        categoryScores.push({
          categoryLabel: itemLabel,
          categoryScore: itemScore
        })
      }
    })

    this.events.emit(reviewsListEvents.REVIEWS_LIST_REVIEW_TRANSLATED, {
      id: reviewId,
      status,
      overallRating,
      country,
      partyComp,
      categoryScores
    })
  }

  /**
   * Gets the data from the API
   */
  async fetch () {
    this.element.classList.add(classNames.isLoading)

    try {
      let fetchedData = null
      if (this.url) {
        const fetchUrl = getUrlFromString(this.url, this.extraParams)
        fetchedData = await fetchJsonData(fetchUrl).then((result) => {
          const data = result.data[0]

          let amount
          let reviews
          let reviewsAmountOfTravelerTypes

          if (data) {
            amount = data.amount
            reviews = data.reviews
            reviewsAmountOfTravelerTypes = data.reviewsAmountOfTravelerTypes
          } else {
            amount = 0
            reviews = []
            reviewsAmountOfTravelerTypes = []
          }

          this.events.emit(reviewsListEvents.REVIEWS_LIST_DATA_LOADED, data)

          this.updateTotalResults(amount)
          this.updatePagination(this.pagination, { silent: true })
          this.resolveSetTravelFilterDropdownPromise(reviewsAmountOfTravelerTypes)

          return {
            amount,
            reviews
          }
        })

        this.reviewsOverallTitle.innerHTML = fetchedData.amount + ' ' + this.overallTitleText
      }

      if (fetchedData && fetchedData.amount > 0) {
        this.element.classList.remove(classNames.isLoading)
        this.noItemsArea.classList.add(classNames.isHidden)
        this.element.classList.remove(classNames.noReviews)
        this.element.classList.add(classNames.hasData)
        this.render(fetchedData.reviews)
        this.setTranslateListeners()
      } else {
        this.element.classList.add(classNames.noReviews)
        this.noItemsArea.classList.remove(classNames.isHidden)
        this.element.classList.remove(classNames.isLoading)
      }

      this.element.classList.remove(classNames.notLoaded)
      this.element.classList.remove(classNames.isHidden)
      this.reviewsLoaderIcon.classList.add(classNames.isHidden)
    } catch (e) {
      if (window.newrelic) {
        window.newrelic.noticeError('[ReviewsList] Error fetching data:' + e)
      }
    }
  }

  /**
   * Update Total Results
   *
   * @param {Number} totalResults  - Total results of the fetch
   */
  updateTotalResults (totalResults) {
    const currentPage = this.pagination.offset === 0 ? 1 : (this.pagination.offset / this.pagination.limit) + 1
    const totalPages = Math.ceil(totalResults / this.pagination.limit)
    this.pagination.currentPage = currentPage
    this.pagination.totalPages = totalPages
  }

  /**
   * Update Total Results
   *
   * @param {Object} props  - The pagination props
   * @param {Object} options  - The pagination options
   */
  updatePagination (props = this.pagination, options) {
    const { totalPages, currentPage } = props
    this.childComponents.pagination.setProps({ totalPages, currentPage }, options)
  }

  /**
   * Renders a reviews item
   *
   * @param {Number} reviewItems - Total results of the fetch
   */
  render (reviewItems) {
    this.itemsElement.innerHTML = reviewItems.map((item) => {
      return ReviewsListItemTemplate(item, this.elementId, this.showScores, this.showPartyComposition)
    }).join('')
    Component.initDocumentComponentsFromAPI(this.element)
    this.element.classList.add(classNames.in)
  }

  /**
   * Init child components events
   */
  bindEvents () {
    if (this.childComponents.pagination) {
      this.childComponents.pagination.events.on('change', (data) => {
        const newOffset = ((data.currentPage - 1) * this.pagination.limit) || null
        this.pagination.offset = newOffset
        this.setExtraParameters({
          offset: newOffset
        })

        this.element.scrollIntoView(scrollConfig)
        this.events.emit(reviewsListEvents.REVIEWS_LIST_PAGINATION, data)

        this.fetch()
      })
    }
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {string} - Returns the api url
   */
  getExtraParameters () {
    let travelFilterProcessed = ''
    let showOnlyInWebsiteLanguage = ''
    if (this.travelFilter) {
      travelFilterProcessed = this.getOptionsSelected(this.travelFilter).join(',')
    }
    if (this.languageSwitch) {
      showOnlyInWebsiteLanguage = this.languageSwitch.checked
    }

    const extraParamsElements = this.element.querySelectorAll(attr.extraParams)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          obj.sorting = this.sortingElement.getValue()
          obj.travelertypes = travelFilterProcessed
          obj.showOnlyInWebsiteLanguage = showOnlyInWebsiteLanguage
          return obj
        }, {})
      : undefined

    return extraParams
  }

  /**
   * Returns the extra parameters that are exposed in the DOM but with no Page limit
   *
   * @returns {string} - Returns the api url
   */
  getExtraParametersNoLimit () {
    const extraParamsElements = this.element.querySelectorAll(attr.extraParams)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          if (el.name !== 'limit') {
            obj[el.name] = el.value
          }
          return obj
        }, {})
      : undefined

    return extraParams
  }

  /**
   * Sets extra params
   *
   * @param {Object} params - The params to be set (if null, input will be deleted)
   */
  setExtraParameters (params) {
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue === null) {
        delete this.extraParams[paramName]
      } else {
        this.extraParams[paramName] = paramValue
      }
    })
  }

  /**
   * Creates a new ReviewsList for every element on document with targeted attributes.
   *
   * @returns {ReviewsList[]} - The created instances.
   */
  static createInstancesOnDocument () {
    const currentWidgets = window.document.querySelectorAll(`[data-js-api="${widgetApi}"]`)
    const instances = []
    for (let i = 0; i < currentWidgets.length; i++) {
      instances.push(ReviewsList.createInstanceOnElement(currentWidgets[i]))
    }
    return instances
  }

  /**
   * Creates a new ReviewsList for single element.
   *
   * @returns {ReviewsList} - Self instance.
   */
  static createInstanceOnElement (element, reviewsListData = {}) {
    return new ReviewsList(element, reviewsListData)
  }
}
