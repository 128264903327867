import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getDataPrefixed } from '../../../js/document/html-helper'

const SmartBanner = require('smart-app-banner/index')

const widgetApi = 'w-smart-app-banner'
const widgetDataPrefix = 'wSmartAppBanner__'
const widgetQueries = {
  linkElement: '.smartbanner-button'
}

const options = {
  language: 'nl',
  buttonText: '',
  appName: '',
  appAuthor: '',
  appIosOnStoreText: '',
  appAndroidOnStoreText: '',
  appIosPriceText: '',
  appAndroidPriceText: '',
  appIcon: null
}

/**
 * Smart App Banner
 *
 */
export default class SmartAppBanner {
  /**
   * Creates a new smart app banner, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    if (!element) { return }
    this.element = element
    this.options = {
      ...options,
      ...getDataPrefixed(this.element, widgetDataPrefix)
    }
    this._getInnerBannerApi()
  }

  _createBanner () {
    const kudagoOptions = {
      daysHidden: parseInt(this.options.closedExpirationDays) || 15,
      daysReminder: parseInt(this.options.viewedExpirationDays) || 90,
      appStoreLanguage: this.options.language,
      title: this.options.appName,
      author: this.options.appAuthor,
      button: this.options.buttonText,
      store: {
        ios: this.options.appIosOnStoreText,
        android: this.options.appAndroidOnStoreText,
        windows: null
      },
      price: {
        ios: this.options.appIosPriceText,
        android: this.options.appAndroidPriceText,
        windows: ''
      },
      theme: 'ios', // force single theme
      icon: this.options.appIcon // full path to icon image if not using website icon image
      //, force: 'android' // Uncomment for platform emulation
    }
    const banner = new SmartBanner(kudagoOptions)
    const link = document.querySelector(widgetQueries.linkElement)
    if (link) {
      link.href = link.href.replace('http://', 'https://')
    }
    return banner
  }

  _getInnerBannerApi () {
    this.banner = this.banner || this._createBanner()
    return this.banner
  }
}

registerWidget(SmartAppBanner, widgetApi)
