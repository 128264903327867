import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getUrlFromString } from '../../../js/document/url'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { ReviewsTeaserItemsTemplate } from './w-reviews-teaser-items.template'
import { LoaderTemplate } from '../../components/loader/c-loader.template'
import Component from '../../../js/core/component/component'
import { language } from '../../../js/user/locale-settings'
import webStorage from '../../../js/document/web-storage'
import { apiCaller } from '../../../js/helpers/api-caller'
import { reviewsTeaserEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'

// Ensure other child component APIs are loaded on time
require('../reviews-list/main')
require('../../components/number-score/main')
require('../../components/collapse/main')

const EventEmitter = require('eventemitter3')
const widgetApi = 'w-reviews-teaser'
const reviewsListWidgetApi = 'w-reviews-list'
const numberScoreComponentApi = 'c-number-score'
const btnComponentApi = 'c-btn'
const modalV2Api = 'c-modal-v2'

const widgetQueries = {
  reviewsListWidgetsSelector: `[data-js-api="${reviewsListWidgetApi}"]`,
  widgetWrapper: `[data-${widgetApi}--wrapper]`,
  reviewsElement: `[data-${widgetApi}--item]`,
  reviewsElementsWrapper: `[data-${widgetApi}--reviews-items]`,
  overviewNumberScore: `[data-${widgetApi}--overview__number-score]`,
  overviewLoader: `[data-${widgetApi}__loader`,
  numberScoreSelector: `[data-js-component="${numberScoreComponentApi}"]`,
  url: `data-${widgetApi}__url`,
  messageArea: `[data-${widgetApi}--msg-area]`,
  goToReviewsBtn: `[data-${widgetApi}--btn]`,
  translateButton: `[data-${widgetApi}--review-translate--btn]`,
  viewOriginalTextButton: `[data-${widgetApi}--review-original--btn]`,
  translatedTextArea: `[data-${widgetApi}--review-translate]`,
  itemContent: `[data-${widgetApi}--review-content]`,
  reviewsModal: `[data-${widgetApi}--modal]`,
  reviewsTab: '[data-result="reviews"]',
  overviewNumberScoreGrade: `[data-${numberScoreComponentApi}__grade]`,
  overviewNumberScoreTitle: `[data-${numberScoreComponentApi}__title]`,
  overviewNumberScoreLabelText: `[data-${numberScoreComponentApi}__label-text]`
}

const classNames = {
  isHidden: 'is-hidden',
  isUnresolved: 'is-unresolved',
  isTransalted: 'is-translated',
  isLoaded: 'is-loaded',
  isClicable: 'is-clicable',
  empty: 'empty',
  loaderIcon: `${widgetApi}__comment-translate-loader`,
  numberScoreTeaser: `${widgetApi}__number-score-teaser`,
  numberScoreLabels: `${numberScoreComponentApi}__title-label`,
  numberScoreTitle: `${numberScoreComponentApi}__title`,
  loaderTeaserIcon: `${widgetApi}__number-score-teaser-loader`
}

export const attr = {
  extraParams: `[data-${widgetApi}__extra-params]`,
  track: 'data-track',
  method: `data-${widgetApi}__method`,
  translateUrl: `data-${widgetApi}__translate-url`,
  modalId: `data-${modalV2Api}__id`
}

export const settings = {
  numberScoreTeaser: 'number-score-teaser',
  numberScoreOverview: 'number-score-overview',
  seeAllReviews: 'see-all-reviews'
}

export default class ReviewsTeaser {
  /**
    * Creates a new ReviewsTeaser.
    *
    * @constructor
    * @param {HTMLElement} element - The HTML element.
    */
  constructor (element) {
    this.element = element
    this.elementId = this.element.id
    this.url = this.element.getAttribute(widgetQueries.url)
    this.extraParams = this.getExtraParameters()
    this.messageArea = this.element.querySelector(widgetQueries.messageArea)
    this.reviewsElementsWrapper = this.element.querySelector(widgetQueries.reviewsElementsWrapper)
    this.goToReviewsBtn = this.element.querySelector(widgetQueries.goToReviewsBtn)
    this.widgetWrapper = this.element.querySelector(widgetQueries.widgetWrapper)
    this.reviewsModal = this.element.querySelector(widgetQueries.reviewsModal)
    this.options = {
      method: this.element.getAttribute(attr.method),
      url: this.element.getAttribute(attr.translateUrl)
    }
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    this._renderNumberScoreTeaser()

    this.fetch()
  }

  /**
   * If in the PDP, number score with overall rating is rendered, we need to render the same number score in the reviews number score teaser
   */
  _renderNumberScoreTeaser () {
    this.numberScoreTeaser = document.querySelector(`.${classNames.numberScoreTeaser}`) // number score teaser

    if (this.numberScoreTeaser) {
      const numberScoreTitle = this.numberScoreTeaser.querySelector(`.${classNames.numberScoreTitle}`) // number score title
      if (numberScoreTitle) {
        numberScoreTitle.innerHTML += LoaderTemplate({ size: 'tiny', extraClasses: classNames.loaderTeaserIcon }) // add loader icon;
      }

      this.events.on(reviewsTeaserEvents.REVIEWS_TEASER_LOADED, () => {
        const numberScoreDonorWrapper = this.element.querySelector(widgetQueries.overviewNumberScore)
        const numberScoreDonor = numberScoreDonorWrapper ? numberScoreDonorWrapper.querySelector(`[data-js-component="${numberScoreComponentApi}"]`) : null

        if (numberScoreDonor) {
          this.numberScoreTeaser.querySelector(`.${classNames.numberScoreLabels}`).innerHTML = numberScoreDonor.querySelector(`.${classNames.numberScoreLabels}`).innerHTML
          Component.initDocumentComponentsFromAPI(this.numberScoreTeaser)

          const numberScoreTeaserInfoBtn = this.numberScoreTeaser.querySelector(`.${classNames.numberScoreLabels}`).querySelector(`[data-js-component="${btnComponentApi}"]`)
          if (numberScoreTeaserInfoBtn) {
            const infoModal = document.querySelector(`#${numberScoreTeaserInfoBtn.getAttribute(attr.modalId)}`)
            if (infoModal) numberScoreTeaserInfoBtn.addEventListener('click', () => { infoModal[modalV2Api].open() })
          }
        }

        this.numberScoreTeaser.classList.add(classNames.isLoaded)
      })
    }
  }

  /**
   * Gets the data from the API
   */
  async fetch () {
    try {
      const fetchUrl = getUrlFromString(this.url, this.extraParams)
      const fetchedData = await fetchJsonData(fetchUrl).then((result) => {
        const data = result.data[0]
        if (data) {
          return {
            amount: data.amount,
            reviews: data.reviews,
            reviewsAmountOfTravelerTypes: data.reviewsAmountOfTravelerTypes
          }
        } else {
          return {
            amount: 0,
            reviews: [],
            reviewsAmountOfTravelerTypes: []
          }
        }
      })

      this.resolveTeaserReviews(fetchedData)
      this.events.emit(reviewsTeaserEvents.REVIEWS_TEASER_LOADED)
    } catch (e) {
      if (window.newrelic) {
        window.newrelic.noticeError('[ReviewsTeaser] Error with:' + e)
      }
    }
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {string} - Returns the api url
   */
  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(attr.extraParams)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined

    return extraParams
  }

  /**
   * Sets listeners for translate button
   */
  setTranslateListeners () {
    const reviewsElements = this.element.querySelectorAll(widgetQueries.reviewsElement)
    reviewsElements.forEach(item => {
      if (item.querySelector(widgetQueries.translateButton)) {
        item.querySelector(widgetQueries.translateButton).addEventListener('click', () => this.translateReview(item))
        item.querySelector(widgetQueries.viewOriginalTextButton).addEventListener('click', () => this.viewOriginalTextReview(item))
      }
    })
  }

  setCTAListeners (overviewNumber) {
    if (overviewNumber) {
      if (overviewNumber.querySelector(widgetQueries.overviewNumberScoreGrade)) overviewNumber.querySelector(widgetQueries.overviewNumberScoreGrade).addEventListener('click', (ev) => { this.openReviewsModal(settings.numberScoreOverview, ev) })
      if (overviewNumber.querySelector(widgetQueries.overviewNumberScoreTitle)) overviewNumber.querySelector(widgetQueries.overviewNumberScoreTitle).addEventListener('click', (ev) => { this.openReviewsModal(settings.numberScoreOverview, ev) })
      if (overviewNumber.querySelector(widgetQueries.overviewNumberScoreLabelText)) overviewNumber.querySelector(widgetQueries.overviewNumberScoreLabelText).addEventListener('click', (ev) => { this.openReviewsModal(settings.numberScoreOverview, ev) })
    }

    this.events.on(reviewsTeaserEvents.REVIEWS_TEASER_LOADED, () => {
      const numberScoreTeaser = document.querySelector(`.${classNames.numberScoreTeaser}`)
      if (numberScoreTeaser) {
        if (numberScoreTeaser.querySelector(widgetQueries.overviewNumberScoreGrade)) numberScoreTeaser.querySelector(widgetQueries.overviewNumberScoreGrade).addEventListener('click', (ev) => { this.openReviewsModal(settings.numberScoreTeaser, ev) })
        if (numberScoreTeaser.querySelector(widgetQueries.overviewNumberScoreTitle)) numberScoreTeaser.querySelector(widgetQueries.overviewNumberScoreTitle).addEventListener('click', (ev) => { this.openReviewsModal(settings.numberScoreTeaser, ev) })
        if (numberScoreTeaser.querySelector(widgetQueries.overviewNumberScoreLabelText)) numberScoreTeaser.querySelector(widgetQueries.overviewNumberScoreLabelText).addEventListener('click', (ev) => { this.openReviewsModal(settings.numberScoreTeaser, ev) })

        numberScoreTeaser.classList.add(classNames.isClicable)
      }
    })

    this.goToReviewsBtn.addEventListener('click', (ev) => { this.events.emit(reviewsTeaserEvents.REVIEWS_TEASER_MODAL_OPENED, settings.seeAllReviews, ev) })

    const reviewsListInsideModal = this.reviewsModal.querySelector(widgetQueries.reviewsListWidgetsSelector)
    if (reviewsListInsideModal) {
      this.reviewsModal[modalV2Api].events.on('opened', () => {
        reviewsListInsideModal[reviewsListWidgetApi].fetch()
      })
    }
  }

  openReviewsModal (source, event) {
    this.reviewsModal[modalV2Api].open()
    this.events.emit(reviewsTeaserEvents.REVIEWS_TEASER_MODAL_OPENED, source, event)
  }

  /**
   * Translate the review
   *
   * @param {Object} review  - Review item that is going to be translated
   */
  async translateReview (review) {
    const reviewId = review.getAttribute('id')
    const loaderIcon = review.querySelector(`.${classNames.loaderIcon}`)
    const translatedTextContainer = review.querySelector(widgetQueries.translatedTextArea)
    const translateButton = review.querySelector(widgetQueries.translateButton)
    const viewOriginalTextButton = review.querySelector(widgetQueries.viewOriginalTextButton)

    loaderIcon.classList.remove(classNames.isHidden)
    const originalText = review.querySelector(widgetQueries.itemContent).querySelector('.m-body').innerHTML

    const translationId = reviewId + '-' + language
    const storagedTranslation = webStorage.local.get(translationId)

    // first we check if the translate exist in the localstorage, if not, we proced with the translations
    try {
      let translatedText = ''
      if (storagedTranslation) {
        translatedText = storagedTranslation
      } else {
        const data = { text: originalText }
        const result = await apiCaller(this.options.url, { body: data, method: this.options.method })

        if (result.success) {
          translatedText = result.response.text
          webStorage.local.set(translationId, translatedText)
        }
      }

      if (translatedText !== '') {
        review.classList.add(classNames.isTransalted)
        translatedTextContainer.innerText = translatedText
        translatedTextContainer.classList.remove(classNames.empty)
        translateButton.classList.add(classNames.isHidden)
        loaderIcon.classList.add(classNames.isHidden)
        viewOriginalTextButton.classList.remove(classNames.isHidden)
      } else {
        loaderIcon.classList.add(classNames.isHidden)
      }
    } catch (error) {
      if (window.newrelic) {
        window.newrelic.noticeError('[ReviewsTeaser] Error with translation:' + error)
      }
    }
  }

  /**
   * view original review
   *
   * @param {Object} review  - Review item that is going to turn back to show original text
   */
  viewOriginalTextReview (review) {
    const translatedTextContainer = review.querySelector(widgetQueries.translatedTextArea)
    const translateButton = review.querySelector(widgetQueries.translateButton)
    const viewOriginalTextButton = review.querySelector(widgetQueries.viewOriginalTextButton)

    review.classList.remove(classNames.isTransalted)
    translatedTextContainer.innerText = ''
    translatedTextContainer.classList.add(classNames.empty)
    translateButton.classList.remove(classNames.isHidden)
    translatedTextContainer.classList.add(classNames.isHidden)
    viewOriginalTextButton.classList.add(classNames.isHidden)
  }

  /**
   * resolve teaser reviews
   *
   * @param {Object} response  - response coming from the call of the API
   */
  resolveTeaserReviews (response) {
    if (response && response.amount > 0) {
      const overviewNumber = this.element.querySelector(widgetQueries.overviewNumberScore)
      if (overviewNumber) {
        this.element.querySelector(widgetQueries.overviewLoader).classList.add(classNames.isHidden)
      }
      this.setCTAListeners(overviewNumber)

      this.element.classList.remove(classNames.isUnresolved)
      this.reviewsElementsWrapper.innerHTML = ReviewsTeaserItemsTemplate(response.reviews, this.elementId)
      Component.initDocumentComponentsFromAPI(this.element)
      this.setTranslateListeners()
    } else {
      this.goToReviewsBtn.classList.add(classNames.isHidden)
      this.messageArea.classList.remove(classNames.isHidden)
      this.widgetWrapper.classList.add(classNames.isHidden)
    }
  }
}

registerWidget(ReviewsTeaser, widgetApi)
