import { TitleTemplate } from '../../components/title/c-title.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The RefundResponseTemplate contains all data to render confirmation screen
 */

export const defaultRefundResponseTemplate = {
  isSuccess: false,
  title: '',
  text: '',
  confirm: ''
}

export const RefundResponseTemplate = (d) => {
  d = { ...defaultRefundResponseTemplate, ...d }
  return `
      <div class="w-refund__body sp-stack-children--medium w-refund__response">
        <div class="m-icon  m-icon--size-huge ${(d.isSuccess ? ' w-refund__confirmed__icon m-icon--checkmark-circle' : ' w-refund__error__icon  m-icon--information-circle')}">
          ${TitleTemplate({
            text: d.title,
            size: 'default',
            extraClasses: (d.isSuccess ? 'w-refund__confirmed__title' : 'w-refund__error__title')
          })}
        </div>
        <div class="m-body">
           ${d.text}
        </div>
        <div class="w-refund__buttons">
          ${d.confirm
            ? `${BtnTemplate({
              text: d.confirm,
              variant: 'flow',
              jsApi: true,
              attributes: d.isSuccess ? { 'data-w-refund__options-done': '' } : { 'data-w-refund__close-modal': '' }
            })}`
            : ''}
        </div>
    </div>
    `
}
