import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush, getDataPrefixed, moveChildrenFrom } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { VoucherTemplate } from './w-voucher.template'
import { RefundInfoTemplate } from '../voucher-check-balance/w-voucher-check-balance.info.template'
import RefundWidget from '../refund/main'
import { fromCamelCase } from '../../../js/helpers/string'

const EventEmitter = require('eventemitter3')

const widgetApi = 'w-voucher'

const attr = {
  voucherCode: `data-${widgetApi}__code`,
  extraParam: 'wVoucher__refundExtraParam'
}

const widgetQueries = {
  refund: `[data-${widgetApi}__refund]`,
  refundLink: `[data-${widgetApi}__refund-link]`
}

export default class Voucher {
  /**
   * Creates a voucher widget
   *
   * @constructor
   *
   * @param {HTMLElement} element -
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = options
    this.events = new EventEmitter()

    this.voucherCode = this.element.getAttribute(attr.voucherCode)

    this.texts = this.getTexts((options.data && options.data.id) ? options.data.id : undefined)
    if (options.data) {
      this._createHtml()
    }
    this._getHtmlElements()
    this._bindEvents()
  }

  _getHtmlElements () {
    if (!this.refundApi) {
      const refundElement = this.element.querySelector(widgetQueries.refund)
      if (refundElement) {
        this.refundApi = refundElement['w-refund']
      }
    }
    this.refundLinkElement = this.element.querySelector(widgetQueries.refundLink)
  }

  _createHtml () {
    const refundModalId = this.options.data.attributes ? `${this.options.data.attributes[attr.refundId]}Modal` : ''
    const html = elementFromString(VoucherTemplate({ ...this.texts, ...this.options.data, refundModalId }))
    moveChildrenFrom(html, this.element, { flush: true, attributes: true })
    Component.initDocumentComponentsFromAPI(this.element)
    const refundElement = this.element.querySelector(widgetQueries.refund)
    if (refundElement) {
      this.refundApi = new RefundWidget(refundElement, {
        data: {
          id: this.options.data.refundId,
          refundRequestUrl: this.options.data.refundRequestUrl,
          showAllCountries: this.options.data.showAllCountries,
          modalId: refundModalId,
          track: this.options.data.refundTrack,
          attributes: this._getRefundAttributes()
        }
      })
    }
  }

  _getRefundAttributes () {
    const extraParams = getDataPrefixed(this.element, attr.extraParam)
    const attributes = {}
    Object.entries(extraParams).forEach(([field, value]) => {
      const newField = `data-w-refund__extra-param-${fromCamelCase(field)}`
      attributes[newField] = value
    })
    return attributes
  }

  _bindEvents () {
    this.element.querySelectorAll('.w-voucher__code').forEach(el => {
      el.addEventListener('click', (e) => {
        e.preventDefault()
        window.getSelection().selectAllChildren(
          el.querySelector('.w-voucher__code-guid')
        )

        document.execCommand('copy')

        window.getSelection().empty()

        const tooltip = el.querySelector('.w-voucher__code-tooltip')
        tooltip.innerHTML = this.element.dataset.wVoucher__copiedCodeText
      })

      el.addEventListener('mouseleave', (e) => {
        const tooltip = el.querySelector('.w-voucher__code-tooltip')
        tooltip.innerHTML = this.element.dataset.wVoucher__clickToCopyText
      })
    })

    this.voucherCode && this.element.addEventListener('click', (ev) => this.events.emit('voucherSelected', this.voucherCode, ev))
    this.refundApi && this.refundApi.events.on('refundRequested', this._renderRefundRequested, this)
  }

  _renderRefundRequested () {
    const html = RefundInfoTemplate({
      ...this.texts.refundAlreadyRequestedAndPendingToBeRefundedText && { text: this.texts.refundAlreadyRequestedAndPendingToBeRefundedText },
      state: 'success'
    })
    const newContent = elementFromString(html)
    flush(this.refundLinkElement)
    this.refundLinkElement.appendChild(newContent)
  }

  getTexts (id = this.element.id) {
    const customLocaleElement = document.querySelector(`[data-uid="${id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }
}

registerWidget(Voucher, widgetApi)
