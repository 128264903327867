import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush, getDataPrefixed, moveChildrenFrom } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import {
  RefundFormBankInfoTemplate,
  RefundFormRemarksAndConditionsTemplate
} from './w-refund-form.template'
import { RefundConfirmationTemplate } from './w-refund-confirmation.template'
import { apiCaller } from '../../../js/helpers/api-caller'
import { RefundResponseTemplate } from './w-refund-response.template'
import registeredEvents from '../../../js/helpers/registered-events'
import { refundEvents } from '../../../js/document/event-types'
import { RefundTemplate } from './refund.template'

const EventEmitter = require('eventemitter3')
const widgetApi = 'w-refund'

const fieldsets = [
  {
    group: 'personal',
    fields: [
      { types: ['sepa', 'nonSepa'], name: 'firstName', size: 'medium', isRequired: ['sepa', 'nonSepa'], fieldType: 'textbox' },
      { types: ['sepa', 'nonSepa'], name: 'lastName', size: 'medium', isRequired: ['sepa', 'nonSepa'], fieldType: 'textbox' },
      { types: ['nonSepa'], name: 'address', size: 'large', isRequired: ['nonSepa'], fieldType: 'textbox' }
    ]
  },
  {
    group: 'bank',
    fields: [
      { types: ['sepa', 'nonSepa'], name: 'iban', size: 'large', isRequired: ['sepa'], fieldType: 'textbox', validationUrl: true, validationQueryKeyWord: 'iban' },
      { types: ['sepa', 'nonSepa'], name: 'swiftCode', size: 'medium', isRequired: ['sepa', 'nonSepa'], fieldType: 'textbox', validationUrl: true, validationQueryKeyWord: 'swift' },
      { types: ['sepa', 'nonSepa'], name: 'country', size: 'medium', isRequired: ['sepa', 'nonSepa'], fieldType: 'dropdown', data: 'countries' },
      { types: ['nonSepa'], name: 'beneficiaryBankName', size: 'medium', isRequired: ['nonSepa'], fieldType: 'textbox' },
      { types: ['nonSepa'], name: 'beneficiaryBankCountry', size: 'medium', isRequired: ['nonSepa'], fieldType: 'dropdown', data: 'countries' },
      { types: ['nonSepa'], name: 'beneficiaryBankAddress', size: 'large', isRequired: ['nonSepa'], fieldType: 'textbox' },
      { types: ['nonSepa'], name: 'routingAndAccountNumber', size: 'large', isRequired: [], fieldType: 'textbox' }
    ]
  }
]

const sepaOption = 'sepa'
const nonSepaOption = 'nonSepa'

export const attr = {
  refundRequestUrl: `data-${widgetApi}__refund-request-url`,
  showAllCountries: `data-${widgetApi}__show-all-countries`,
  modalId: `data-${widgetApi}__modal-id`,
  track: 'data-track',
  extraParam: 'wRefund__extraParam'
}

export const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  optionsConfirm: `[data-${widgetApi}__options-confirm]`,
  optionsDecline: `[data-${widgetApi}__options-decline]`,
  optionsBack: `[data-${widgetApi}__options-back]`,
  optionsDone: `[data-${widgetApi}__options-done]`,
  conditions: `[data-${widgetApi}__conditions]`,
  closeModal: `[data-${widgetApi}__close-modal]`,
  modal: `[data-${widgetApi}__modal]`,
  choiceListApi: 'c-choice-list',
  modalApi: 'c-modal-v2',
  buttonApi: 'c-btn',
  modalContainer: `[data-${widgetApi}__modal-container]`,
  typeChoiceList: `[data-${widgetApi}__type-choice-list]`,
  textbox: `[data-${widgetApi}__textbox`,
  dropdown: `[data-${widgetApi}__dropdown`,
  textboxIcon: '.c-textbox__icon'
}

export const classList = {
  icon: 'm-icon--checkmark',
  loader: ['c-loader', 'c-loader--tiny'],
  hidden: 'is-hidden',
  disabled: 'is-disabled'
}

export default class Refund {
  constructor (element, options = {}) {
    this.element = element
    this.options = options
    this.currentType = sepaOption
    this.nextRender = null
    this.element[widgetApi] = this
    this.apis = {}
    this.data = {}
    this.events = new EventEmitter()
    this.formData = {}
    this.sendFormFlag = false
    this.validateServerSideFieldsFlag = false

    this.texts = this.getTexts((options.data && options.data.id) ? options.data.id : undefined)
    if (options.data) {
      this._createHtml()
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
    this.extraParams = getDataPrefixed(this.element, attr.extraParam)
    this._getHtmlElements()
    this._getData()
    this._attachEvents()
  }

  _getData () {
    this.data.countries = []
    const countries = this.texts.countries && JSON.parse(this.texts.countries)
    const hasCountries = countries && countries.length > 0
    if (hasCountries) {
      this.data.countries = countries
    }
    const allCountries = (this.showAllCountries || !hasCountries) && this.texts.allCountries && JSON.parse(this.texts.allCountries)
    if (allCountries && allCountries.length > 0) {
      if (hasCountries) {
        this.data.countries = this.data.countries.concat([{ id: '-', name: '----------', disabled: true }])
      }
      this.data.countries = this.data.countries.concat(allCountries)
    }
  }

  _createHtml () {
    const html = elementFromString(RefundTemplate(this.options.data))
    moveChildrenFrom(html, this.element, { flush: true, attributes: true })
    Component.initDocumentComponentsFromAPI(this.element)
  }

  _attachEvents () {
    if (this.modalElement) {
      this.modalElement[widgetQueries.modalApi].events.on('closed', () => {
        this._closeModal()
      })
      this.modalElement[widgetQueries.modalApi].events.on('open', () => {
        this.events.emit(refundEvents.REFUND_REQUEST_CLICKED, this.extraParams)
        this._renderForm()
      })
    }
  }

  _getHtmlElements () {
    this.modalElement = this.element.querySelector(widgetQueries.modal)
    this.modalContainer = this.element.querySelector(widgetQueries.modalContainer)
    this.refundRequestUrl = this.element.getAttribute(attr.refundRequestUrl)
    this.showAllCountries = this.element.getAttribute(attr.showAllCountries) != null
    this.modalId = this.element.getAttribute(attr.modalId)
    const modal = this.modalId && this.element.querySelector(`[id='${this.modalId}']`)
    this.modalApi = modal && modal[widgetQueries.modalApi]
  }

  _renderForm () {
    const partialHtml = RefundFormBankInfoTemplate({
      id: this.element.id,
      sepaOption,
      nonSepaOption,
      formData: this.formData,
      fieldsets,
      type: this.currentType,
      texts: this.texts,
      data: this.data,
      track: this.element.hasAttribute(attr.track) ? this.element.attributes[attr.track].value : null
    })
    const html = RefundConfirmationTemplate({
      title: this.texts.refundTitle || '',
      confirm: this.texts.continueButton || '',
      decline: this.texts.declineButton || '',
      html: partialHtml
    })
    this.nextRender = this._renderConditions
    this.previousRender = null
    this._renderHTML(html)
    this._attachFormEvents()
    this._enableFormInputsErrors()
    this._attachInputEventsToValidateServerSide()
  }

  _renderConditions () {
    const partialHtml = RefundFormRemarksAndConditionsTemplate({
      id: this.element.id,
      formData: this.formData,
      texts: this.texts,
      url: this.refundRequestUrl,
      track: this.element.hasAttribute(attr.track) ? this.element.attributes[attr.track].value : null
    })
    const html = RefundConfirmationTemplate({
      texts: this.texts,
      back: this.texts.backButton || '',
      confirm: this.texts.continueButton || '',
      decline: this.texts.declineButton || '',
      html: partialHtml
    })
    this.nextRender = null
    this.previousRender = this._renderForm
    this._renderHTML(html)
    this._attachFormEvents()
  }

  _renderResponse (isSuccess, dataPosted) {
    const html = RefundResponseTemplate({
      isSuccess,
      title: isSuccess ? (this.texts.successfulTitle || '') : (this.texts.failureTitle || ''),
      text: isSuccess ? (this.texts.successfulText || '') : (this.texts.failureText || ''),
      confirm: this.texts.confirmButton || ''
    })

    if (isSuccess) {
      this.events.emit(refundEvents.REFUND_REQUESTED, dataPosted)
    }
    this.previousRender = null
    this._renderHTML(html)
    this._attachFormEvents()
  }

  _attachInputEventsToValidateServerSide () {
    const form = this.modalContainer.querySelector(widgetQueries.form)

    fieldsets.forEach(fieldset => {
      fieldset.fields.filter(f => f.types.includes(this.currentType) && f.validationUrl).forEach(f => {
        const icons = form.querySelectorAll(widgetQueries.textboxIcon)
        icons.forEach(icon => icon.classList.add(classList.hidden))

        if (f.validationQueryKeyWord && this.apis.form) {
          const el = this.apis.form.element.querySelector(`[data-w-refund__field-name="${f.name}"]`)
          const textBoxWrapperIcon = el && el.querySelector(widgetQueries.textboxIcon)

          this.apis[f.name].events.on('blur', () => {
            if (this.apis[f.name].validate().isValid) {
              this._validateInputServerSide(f)
            } else {
              textBoxWrapperIcon && textBoxWrapperIcon.classList.add(classList.hidden)
            }
          })
        }
      })
    })
  }

  async _validateInputServerSide (field) {
    const el = this.apis.form.element.querySelector(`[data-w-refund__field-name="${field.name}"]`)
    const partialUrl = el ? el.getAttribute('data-w-refund__validate-url') : null
    if (partialUrl) {
      let isValidAsync = false
      this._setInputFieldState(field, true, isValidAsync)

      const toBeValidated = []
      const data = this._prepareDataToBeValidated(field)
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        toBeValidated.push(data)
      }

      this.validateServerSideFieldsFlag = true
      if (this.validateServerSideFieldsFlag) {
        isValidAsync = await this._validateFieldsAsync(toBeValidated)
        this.validateServerSideFieldsFlag = false
      }
      this._setInputFieldState(field, false, isValidAsync)

      return isValidAsync
    }
  }

  _setInputFieldState (field, isValidating, isValid) {
    if (field.validationUrl && field.validationQueryKeyWord && this.apis.form) {
      const el = this.apis[field.name].element
      const partialUrl = el ? el.getAttribute('data-w-refund__validate-url') : null
      const textBoxWrapperIcon = el ? el.querySelector(widgetQueries.textboxIcon) : null
      if (isValidating) {
        partialUrl && this._setInputDisabledState(el, isValidating)
        partialUrl && this._setButtonsState(isValidating)
        if (partialUrl && textBoxWrapperIcon) {
          textBoxWrapperIcon.classList.remove(classList.icon)
          textBoxWrapperIcon.classList.add(...classList.loader)
          textBoxWrapperIcon.classList.remove(classList.hidden)
        }
      } else {
        partialUrl && this._setInputDisabledState(el)
        partialUrl && this._setButtonsState(isValidating)
        if (textBoxWrapperIcon) {
          textBoxWrapperIcon.classList.remove(...classList.loader)
          if (isValid) {
            textBoxWrapperIcon.classList.add(classList.icon)
          }
        }
      }
    }
  }

  _setInputDisabledState (el, isDisabled = false) {
    if (!el) { return }
    const input = el.querySelector('input')
    if (input) { input.disabled = isDisabled }
    isDisabled ? el.classList.add(classList.disabled) : el.classList.remove(classList.disabled)
  }

  _enableFormInputsErrors () {
    fieldsets.forEach(fieldset => {
      fieldset.fields.filter(f => f.types.includes(this.currentType)).forEach(f => {
        this.apis[f.name].enableErrors()
      })
    })
  }

  _attachFormEvents () {
    const confirm = this.modalContainer.querySelector(widgetQueries.optionsConfirm)
    const decline = this.modalContainer.querySelector(widgetQueries.optionsDecline)
    const back = this.modalContainer.querySelector(widgetQueries.optionsBack)
    const done = this.modalContainer.querySelector(widgetQueries.optionsDone)

    this.apis = {
      ...confirm && { confirmButton: confirm[widgetQueries.buttonApi] },
      ...decline && { declineButton: decline[widgetQueries.buttonApi] },
      ...back && { backButton: back[widgetQueries.buttonApi] },
      ...done && { doneButton: done[widgetQueries.buttonApi] }
    }

    confirm && confirm.addEventListener('click', this._clickedConfirm.bind(this))
    decline && decline.addEventListener('click', this._clickedClose.bind(this))
    back && back.addEventListener('click', this._clickedBack.bind(this))
    done && done.addEventListener('click', this._clickedClose.bind(this))

    const form = this.modalContainer.querySelector(widgetQueries.form)
    if (form) {
      this.apis = { ...this.apis, ...{ form: form['c-form'] }, ...this._getFormControls(form) }
    }

    this.apis && this.apis.type && this.apis.type.events.on('changedOptions', (data) => { this._changeType(data) })

    const closeModal = this.element.querySelector(widgetQueries.closeModal)
    closeModal && closeModal.addEventListener('click', this._clickedClose.bind(this))
  }

  _clickedConfirm (ev) {
    ev.preventDefault()
    this._sendForm()
  }

  _clickedBack (ev) {
    ev.preventDefault()
    if (this.previousRender) {
      this.previousRender()
    }
  }

  _clickedClose (ev) {
    ev.preventDefault()
    this._closeModal()
  }

  _closeModal () {
    this.modalApi.close()
    this.sendFormFlag = false
    this.validateServerSideFieldsFlag = false
    this.formData = {}
    this.currentType = sepaOption
  }

  _changeType () {
    this._getFormData()
    if (this.formData.type === sepaOption) {
      this.currentType = sepaOption
    } else if (this.formData.type === nonSepaOption) {
      this.currentType = nonSepaOption
    }
    this._renderForm()
  }

  _getFormControls (formEl) {
    const elements = {}
    const apis = {}

    elements.type = formEl.querySelector(widgetQueries.typeChoiceList)
    if (elements.type) { apis.type = elements.type['c-dropdown'] }
    fieldsets.forEach(fieldset => {
      fieldset.fields.filter(f => f.types.includes(this.currentType)).forEach(f => {
        elements[f.name] = formEl.querySelector(`${widgetQueries.textbox}="${f.name}"]`)
        if (elements[f.name]) { apis[f.name] = elements[f.name]['c-textbox'] }
        elements[f.name] = formEl.querySelector(`${widgetQueries.dropdown}="${f.name}"]`)
        if (elements[f.name]) { apis[f.name] = elements[f.name]['c-dropdown'] }
      })
    })

    return apis
  }

  _getFormData () {
    this.formData.type = this.apis.type ? this.apis.type.getProp('value') : this.formData.type
    fieldsets.forEach(fieldset => {
      fieldset.fields.filter(f => f.types.includes(this.currentType)).forEach(f => {
        this.formData[f.name] = this.apis[f.name] ? this.apis[f.name].getProp('value') : this.formData[f.name]
      })
    })
  }

  _getDataToBeValidatedServerSide () {
    const toBeValidated = []
    fieldsets.forEach(fieldset => {
      fieldset.fields.filter(f => f.types.includes(this.currentType)).forEach(field => {
        if (field.validationUrl && field.validationQueryKeyWord && this.apis.form) {
          const data = this._prepareDataToBeValidated(field)
          if (Object.keys(data).length !== 0 && data.constructor === Object) {
            toBeValidated.push(data)
          }
        }
      })
    })
    return toBeValidated
  }

  _prepareDataToBeValidated (field) {
    let dataToBeValidated = {}
    if (field.validationUrl && field.validationQueryKeyWord && this.apis.form) {
      const el = this.apis.form.element.querySelector(`[data-w-refund__field-name="${field.name}"]`)
      if (el) {
        const partialUrl = el.getAttribute('data-w-refund__validate-url')
        if (partialUrl) {
          let val = this.apis[field.name].getProp('value')
          val = val.replace(/[/.]/g, '')
          if (val) {
            dataToBeValidated = {
              name: field.name,
              url: `${partialUrl}?${field.validationQueryKeyWord}=${val}&fieldName=${field.name}`,
              method: 'GET'
            }
          }
        }
      }
    }
    return dataToBeValidated
  }

  async _validateFieldsAsync (toBeValidated) {
    const validationPromises = toBeValidated.map(item => apiCaller(item.url, { method: item.method, timeout: 10000 }))
    return Promise.all(validationPromises).then(results => {
      let noErrors = true
      results.forEach((result, index) => {
        if (!result.success || (result.success && result.response && !result.response.isValid)) {
          noErrors = false
          const fieldName = toBeValidated[index].name
          this.apis[fieldName].setValidityResult(noErrors, [this.texts[`${fieldName}FieldMessagePattern`]])
        }
      })
      return noErrors
    })
  }

  async _sendForm () {
    this._setButtonsState(true, true)

    if (!this.apis.form || this.apis.form.validate().some(v => !v.isValid)) {
      this._setButtonsState(false)
      return
    }

    const toBeValidated = this._getDataToBeValidatedServerSide()
    const isValidAsync = await this._validateFieldsAsync(toBeValidated)
    if (!isValidAsync) {
      this._setButtonsState(false)
      return
    }

    if (this.nextRender) {
      this.formData = {}
    }
    this._getFormData()

    const data = {
      ...this.formData,
      ...this.extraParams,
      isSepa: this.formData.type === sepaOption,
      contextItemId: this.element.id
    }

    if (this.nextRender) {
      this.nextRender()
      return
    }

    this.sendFormFlag = true
    const result = await apiCaller(`${this.refundRequestUrl}`, { method: 'POST', body: data })
    if (this.sendFormFlag) {
      this.sendFormFlag = false
      this._renderResponse(result.success && result.response.isValid, data)
    }
  }

  _renderHTML (html) {
    const newContent = elementFromString(html)
    Component.initDocumentComponentsFromAPI(newContent)
    flush(this.modalContainer)
    this.modalContainer.appendChild(newContent)
  }

  _setButtonsState (isDisabled, isLoading = false) {
    this.apis.confirmButton && this.apis.confirmButton.setProps({ disabled: isDisabled, loading: isLoading })
    this.apis.declineButton && this.apis.declineButton.setProp('disabled', isDisabled)
    this.apis.backButton && this.apis.backButton.setProp('disabled', isDisabled)
  }

  getTexts (id = this.element.id) {
    const customLocaleElement = document.querySelector(`[data-uid="${id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }
}

registerWidget(Refund, widgetApi)
