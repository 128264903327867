import { encodeAttributes } from '../../../js/helpers/string'
import { FormSectionTemplate } from '../../components/form/c-form__section.template'
import { FormFieldsetTemplate } from '../../components/form/c-form__fieldset.template'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { FormTemplate } from '../../components/form/c-form.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { DateSelectorTemplate } from '../../components/date-selector/c-date-selector.template'

/**
 * The VoucherRedeemer contains all data to render voucher redeemer form
 *
 * @typedef {Object}            SliderData
 *
 * @property {String}           id                  - Id of the widget
 * @property {String}           url                 - Url where make the ajax call
 * @property {String}           method              - Method where make the ajax call
 * @property {Boolean}          jsApi               - Enable js widget
 */

export const defaultVoucherRedeemerTemplate = {
  id: '',
  attributes: {
    'data-url': '',
    'data-method': 'GET',
    'data-component-id': ''
  },
  jsApi: false,
  voucherCodeLabel: '',
  voucherCodePlaceholder: '',
  voucherCodePattern: '',
  voucherCodeWarningRequiredMessage: '',
  voucherCodeWarningInvalidMessage: '',
  voucherCodeExtraClasses: '',
  emailLabel: '',
  emailPlaceholder: '',
  emailWarningRequiredMessage: '',
  emailWarningInvalidMessage: '',
  emailExtraClasses: '',
  birthdateLabel: '',
  birthdateDayPlaceholder: '',
  birthdateMonthPlaceholder: '',
  birthdateYearPlaceholder: '',
  birthdateWarningRequiredMessage: '',
  birthdateWarningInvalidMessage: '',
  birthdateExtraClasses: '',
  submitButtonText: '',
  submitButtonCheckingText: '',
  errorMessage: '',
  track: '',
  showCancelButton: '',
  cancelButtonText: ''
}

export const VoucherRedeemerTemplate = (d) => {
  d = { ...defaultVoucherRedeemerTemplate, ...d }
  return `
    <div class="w-voucher-redeemer ${d.extraClasses}"
    ${d.id ? d.id : ''}
    ${d.track ? `data-track=${d.track}` : ''}
    ${d.jsApi ? 'data-js-widget="w-voucher-redeemer"' : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>

      ${FormTemplate({
        hasSubmitButton: false,
        validate: true,
        attributes: {
          'data-w-voucher-redeemer__form': ''
        },
        html: FormContentTemplate(d),
        track: d.track
      })}
    </div>
    `
}

const FormContentTemplate = (d) => {
  return `
    <div class="w-voucher-redeemer__form-body-section">
      ${FormSectionTemplate({
        html: FormSection(d)
      })}
    </div>
    <div class="w-voucher-redeemer__error-message" data-w-voucher-redeemer--error-message-container>${d.errorMessage}</div>
    <div class="w-voucher-redeemer__form-button-section">
      ${d.showCancelButton
        ? BtnTemplate({
          jsApi: true,
          variant: 'flat-neutral',
          ...d.cancelButtonText && { text: d.cancelButtonText },
          extraClasses: 'w-voucher-redeemer__cancel-button',
          attributes: {
            'data-w-voucher-redeemer--cancel-button': ''
          }
        })
      : ''}
      ${BtnTemplate({
        jsApi: true,
        variant: 'secondary',
        ...d.submitButtonText && { text: d.submitButtonText },
        extraClasses: 'w-voucher-redeemer__submit-button',
        attributes: {
          'data-w-voucher-redeemer--submit-button': '',
          'data-w-voucher-redeemer--submit-button-text': d.submitButtonText || '',
          'data-w-voucher-redeemer--submit-button-checking-text': d.submitButtonCheckingText || ''
        }
      })}
    </div>
  `
}

const FormSection = (d) => {
  return `
    ${FormFieldsetTemplate({
      html: `${TextboxTemplate({
        id: `${d.id}_voucher_redeemer_voucher_code`,
        ...d.voucherCodelabel && { label: d.voucherCodelabel },
        hiddenLabel: false,
        ...d.voucherCodePlaceholder && { placeholder: d.voucherCodePlaceholder },
        elementAttributes: {
          ...d.voucherCodePattern && { pattern: d.voucherCodePattern },
          ...d.voucherCodeWarningRequiredMessage && { 'data-message-required': d.voucherCodeWarningRequiredMessage },
          ...d.voucherCodeWarningInvalidMessage && { 'data-message-pattern': d.voucherCodeWarningInvalidMessage },
          'data-track-value': ''
        },
        required: true,
        extraClasses: 'c-form__item ' + (d.voucherCodeExtraClasses ? d.voucherCodeExtraClasses : 'c-form__item--large'),
        attributes: {
          'data-w-voucher-redeemer--voucher-code-input': ''
        }
      })}
      ${TextboxTemplate({
        id: `${d.id}_voucher_redeemer_email`,
        type: 'email',
        ...d.emailLabel && { label: d.emailLabel },
        hiddenLabel: false,
        ...d.emailPlaceholder && { placeholder: d.emailPlaceholder },
        elementAttributes: {
          ...d.emailWarningRequiredMessage && { 'data-message-required': d.emailWarningRequiredMessage },
          ...d.emailWarningInvalidMessage && { 'data-message-typemismatch': d.emailWarningInvalidMessage }
        },
        required: true,
        extraClasses: 'c-form__item ' + (d.emailExtraClasses ? d.emailExtraClasses : 'c-form__item--large'),
        attributes: {
          'data-w-voucher-redeemer--email-input': ''
        }
      })}
      ${DateSelectorTemplate({
        id: `${d.id}_voucher_redeemer_birthdate`,
        ...d.birthdateLabel && { mainLabel: d.birthdateLabel },
        ...d.birthdateDayPlaceholder && { dayPlaceholder: d.birthdateDayPlaceholder },
        ...d.birthdateMonthPlaceholder && { monthPlaceholder: d.birthdateMonthPlaceholder },
        ...d.birthdateYearPlaceholder && { yearPlaceholder: d.birthdateYearPlaceholder },
        ...d.birthdateWarningRequiredMessage && { messageRequired: d.birthdateWarningRequiredMessage },
        ...d.birthdateWarningInvalidMessage && { messageInvalid: d.birthdateWarningInvalidMessage },
        required: true,
        blockModifier: true,
        extraClasses: 'c-form__item ' + (d.birthdateExtraClasses ? d.birthdateExtraClasses : 'c-form__item--large'),
        attributes: {
          'data-w-voucher-redeemer--birthdate-selector': ''
        }
      })}
   `
    })}
  `
}
