import { encodeAttributes } from '../../../js/helpers/string'
import { PriceTemplate } from '../../components/price/c-price.template'
import { getTokenClass } from '../../../js/helpers/tokens'
import { BoxTemplate } from '../../components/box/c-box.template'
import { LabelTemplate } from '../../components/label/c-label.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

// Import tokens
const tokensShared = require('./data/w-voucher__tokens.json')['w-voucher']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/voucher/data/w-voucher__tokens.json`)['w-voucher'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The Voucher contains all data to render voucher
 *
 * @typedef {Object}            SliderData
 *
 * @property {Boolean}          jsApi               - Enable js widget
 */

export const defaultVoucherTemplate = {
  jsApi: false,
  extraClasses: '',
  attributes: {},
  description: '',
  expirationText: '',
  price: '',
  bonus: '',
  link: {},
  voucherCodeTitle: '',
  voucherCodeValue: '',
  voucherCodeClickToCopyText: '',
  voucherCodeCopiedCodeText: '',
  variant: 'default',
  icon: 'default',
  refundModalId: '',
  refundButtonText: '',
  refundRequestAlreadySentText: '',
  refundAlreadyRequestedAndPendingToBeRefundedText: '',
  alreadyRefundedText: '',
  enableRefund: false,
  canRequestRefund: false,
  isAlreadyRequestedAndPendingToBeRefunded: false,
  isAlreadyRefunded: false
}

export const VoucherTemplate = (d) => {
  d = { ...defaultVoucherTemplate, ...d }
  return `
    <div id="${d.id}" class="w-voucher ${getTokenClass('variant', d.variant, tokens)}  ${d.extraClasses}"
    ${d.jsApi ? 'data-js-widget="w-voucher"' : ''}
    ${d.voucherCodeValue
      ? `data-w-voucher__click-to-copy-text="${d.voucherCodeClickToCopyText}"
        data-w-voucher__copied-code-text="${d.voucherCodeCopiedCodeText}"`
      : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
      ${BoxTemplate({
        extraClasses: 'w-voucher__container',
        html: BoxContent(d)
      })}
      ${d.enableRefund
        ? `<div data-w-voucher__refund-link>
          ${d.refundModalId && d.canRequestRefund && d.refundButtonText
            ? `${BtnTemplate({
              jsApi: true,
              variant: 'flat-neutral',
              block: true,
text: d.refundButtonText,
              extraClasses: 'w-voucher__refund-button',
              attributes: { 'data-c-modal-v2__action': 'open', 'data-c-modal-v2__id': d.refundModalId }
            })}`
            : ''}
            ${d.isAlreadyRequestedAndPendingToBeRefunded && d.refundAlreadyRequestedAndPendingToBeRefundedText
              ? `${RefundInfoTemplate({
                state: 'info',
                text: d.refundAlreadyRequestedAndPendingToBeRefundedText
              })}`
              : ''}
            ${d.isAlreadyRefunded && d.alreadyRefundedText
              ? `${RefundInfoTemplate({
                state: 'info',
                text: d.alreadyRefundedText
              })}`
              : ''}
            </div>
          <div data-w-voucher__refund></div>`
        : ''}
    </div>
    `
}

export const RefundInfoTemplate = (d) => {
  return `${d.text
    ? BoxTemplate({
      variant: 'flat',
      extraClasses: `cs-state-${d.state} cs-state-${d.state}--light w-voucher__refund-message`,
      html: d.text
    })
    : ''}`
}

const BoxContent = (d) => {
  return `${d.link && d.link.href ? LinkInnerContent(d) : DivInnerContent(d)}
    ${d.voucherCodeValue
      ? `<div class="w-voucher__code">
          <div class="w-voucher__code-title">${d.voucherCodeTitle}</div>
          <div class="w-voucher__code-guid">${d.voucherCodeValue}<span class="m-icon m-icon--copy m-icon--size-medium"></span></div>
          <div class="w-voucher__code-tooltip">${d.voucherCodeClickToCopyText}</div>
        </div>`
    : ''}
  `
}

const LinkInnerContent = (d) => {
  return `
    <a class="w-voucher__inner-content" href="${d.link.href}"
        ${d.link.target ? `target="${d.link.target}"` : ''}
        ${d.link.title ? `title="${d.link.title}"` : ''}>
      ${InnerContent(d)}
    </a>`
}

const DivInnerContent = (d) => {
  return `
    <div class="w-voucher__inner-content">
      ${InnerContent(d)}
    </div>`
}

const InnerContent = (d) => {
  return `
    <div class="w-voucher__side">
      <div class="w-voucher__icon m-icon m-icon--${getTokenClass('icon', 'default', tokens)}"></div>
    </div>
    <div class="w-voucher__balance">
      <div class="w-voucher__balance-description">${d.description}</div>
      ${d.bonus && d.bonus.bonusLabel
        ? `<div class="w-voucher__bonus">
            <div class="w-voucher__old-balance-amount">${d.bonus.amountLabel}</div>
             ${LabelTemplate({
               extraClasses: 'w-voucher__balance_bonus_info_label',
               variant: 'info',
               text: d.bonus.bonusLabel
             })}
            </div>`
        : ''}
      ${d.price
        ? PriceTemplate({
          ...d.price,
          size: getTokenClass('priceSize', d.variant, tokens),
          extraClasses: 'w-voucher__balance-price'
        })
        : ''}
      <div class="w-voucher__balance-expiration">${d.expirationText}</div>
    </div>
    ${d.link && d.link.href ? '<span class="w-voucher__link-icon m-icon m-icon--chevron-right"></span>' : ''}
   `
}
