import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'

export const widgetApi = 'w-verify-account'

export const attr = {
  method: `data-${widgetApi}__method`,
  url: `data-${widgetApi}__url`
}

const token = 'token'
const tokenExpired = 'ExpiredToken'

export default class VerifyAccount {
  /**
   * Creates a new VerifyAccount
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = {
      method: this.element.getAttribute(attr.method),
      url: this.element.getAttribute(attr.url),
      ...options
    }
    this.elements = {
      errorMessage: element.querySelector('[data-w-verify-account__error]'),
      expiredTokenMessage: element.querySelector('[data-w-verify-account__expired-token]'),
      loader: element.querySelector('[data-w-verify-account__loader]')
    }

    this.fetchAndUpdate()
  }

  async fetchAndUpdate () {
    const token = this._getVerifyTokenFromUrl()
    const { response, success } = await apiCaller(this.options.url, { method: this.options.method, body: { token } })
    if (success && response.url) this._redirect(response.url)
    if (!success || response.error) this._displayMessage(success ? response.error : null)
  }

  _displayMessage (error) {
    if (error === tokenExpired) {
      this.elements.expiredTokenMessage.classList.remove('is-hidden')
    } else {
      this.elements.errorMessage.classList.remove('is-hidden')
    }
    this.elements.loader.classList.add('is-hidden')
  }

  _redirect (subPath) {
    const url = new URL(window.location.href).origin
    window.location.replace(`${url}${subPath}`)
  }

  _getVerifyTokenFromUrl () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const tokenValue = urlParams.get(token)
    return tokenValue || null
  }
}

registerWidget(VerifyAccount, widgetApi)
