export const da = {
  placeholder: 'Vælg et værelse',
  roomsLeftAvailable: '{n} værelser tilbage',
  lastRoomAvailable: 'Sidste værelse til rådighed'
}

export const de = {
  placeholder: 'Wählen Sie einen Raum aus',
  roomsLeftAvailable: '{n} zimmer übrig',
  lastRoomAvailable: 'Letztes freies Zimmer'
}

export const en = {
  placeholder: 'Select a room',
  roomsLeftAvailable: '{n} rooms left',
  lastRoomAvailable: 'Last room available'
}

export const nb = {
  placeholder: 'Velg et rom',
  roomsLeftAvailable: '{n} rom igjen',
  lastRoomAvailable: 'Siste rom tilgjengelig'
}

export const fr = {
  placeholder: 'Choisissez une chambre',
  roomsLeftAvailable: '{n} chambres restantes',
  lastRoomAvailable: 'Dernière chambre disponible'
}

export const nl = {
  placeholder: 'Kies een kamer',
  roomsLeftAvailable: '{n} kamers over',
  lastRoomAvailable: 'Laatste kamer beschikbaar'
}

export const sv = {
  placeholder: 'Välj ett rum',
  roomsLeftAvailable: '{n} rum kvar',
  lastRoomAvailable: 'Sista rum tillgängligt'
}
