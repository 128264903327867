import { apiCaller } from '../../../../shared/js/helpers/api-caller'
import { SkiPricesTemplate } from './w-ski-prices.template'
import Component from '../../../js/core/component/component'
import { getUrlFromString } from '../../../js/document/url'
import { elementFromString } from '../../../js/document/html-helper'
import { observerAPI, documentObserver } from '../../../js/document/intersector'

const widgetApi = 'w-ski-prices'

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  extraParams: 'input[type="hidden"]',
  tableHeaders: `data-${widgetApi}__table-headers`
}

export default class SkiPrices {
  /**
   * Creates a new SkiPrices
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach TabsFilter
   * @param options
   */
  constructor (element, options = {}) {
    this.element = element
    this.element[widgetApi] = this
    this.extraParams = this.getExtraParamsFromDom()
    this.url = options.url || this.element.getAttribute(widgetQueries.urlAttr)
    this.tableHeaders = this.element.getAttribute(widgetQueries.tableHeaders).split(',')

    this.extraData = {
      contextitemid: '',
      id: '',
      servicetype: ''
    }

    this.defaultAccordionData = {
      id: `${this.element.id}__accordion`,
      size: 'default',
      extraClasses: ''
    }

    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      this.fetch()
      observer.unobserve(this.element)
    })
  }

  /**
   * Gets extra params through hidden inputs on DOM
   *
   * @returns {Object|undefined}
   */
  getExtraParamsFromDom () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    return extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined
  }

  /**
   * Fetches the ski prices data
   *
   * @returns {Object|undefined}
   */
  async fetch () {
    const url = getUrlFromString(this.url, this.extraParams)
    const result = await apiCaller(url.href)
    if (result.success) {
      this.renderSkiPrices(result.response.data[0])
    }
  }

  /**
   * Render the ski prices from current data.
   */
  renderSkiPrices (data) {
    const fragment = document.createDocumentFragment()
    data.skiPriceSections.forEach((skiPriceSection, index) => {
      fragment.appendChild(elementFromString(SkiPricesTemplate(
        {
          tableHeaders: this.tableHeaders,
          id: this.element.id,
          sectionCount: index
        },
        skiPriceSection)))
    })
    this.element.innerHTML = ''
    this.element.appendChild(fragment)
    Component.initDocumentComponentsFromAPI(this.element)
  }

  /**
   * Creates a new SkiPrices for every element on document with targeted attributes.
   *
   * @returns {SkiPrices[]} - The created instances.
   */
  static createInstancesOnDocument () {
    const currentWidgets = window.document.querySelectorAll(`[data-js-api="${widgetApi}"]`)
    const instances = []
    for (let i = 0; i < currentWidgets.length; i++) {
      instances.push(SkiPrices.createInstanceOnElement(currentWidgets[i]))
    }
    return instances
  }

  /**
   * Creates a new SkiPrices for single element.
   *
   * @returns {SkiPrices} - Self instance.
   */
  static createInstanceOnElement (element, skiPricesData = {}) {
    return new SkiPrices(element, skiPricesData)
  }
}
