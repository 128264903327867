/**
 * List of events used by rebooking options
 *
 */

export const rebookingOptions = {
  MODAL_OPENED: 'rebookingOptions.modalOpened',
  SAME_ACCO_LINK_CLICKED: 'rebookingOptions.sameAccoLinkClicked',
  DIFFERENT_ACCO_LINK_CLICKED: 'rebookingOptions.differentAccoLinkClicked',
  ANOTHER_PAGE_LINK_CLICKED: 'rebookingOptions.anotherPageLinkClicked'
}
