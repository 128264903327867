/**
 * The ReviewsTeaserItemsTemplate contains all data needed to hydrate a Reviews teaser widget
 *
 * @typedef {Object}            ReviewsTeaserItemsTemplate
 *
 */

import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import { NumberScoreTemplate } from '../../components/number-score/c-number-score.template'
import { CollapseTriggerTemplate } from '../../components/collapse/c-collapse__trigger.template'
import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { LoaderTemplate } from '../../components/loader/c-loader.template'

const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.reviewsTeaser`)
const languageSite = language

export const defaultReviewsTeaserItemsData = {
  id: '',
  comment: '',
  collapseHeight: 80,
  blockText: true,
  countryCode: '',
  languageCode: ''
}

export const ReviewsTeaserItemsTemplate = (data, reviewsTeaserId) => {
  data = { ...defaultReviewsTeaserItemsData, ...data }

  const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${reviewsTeaserId}"]`)
  let customLocaleData = null
  try {
    customLocaleData = JSON.parse(customLocaleElement.textContent)
  } catch (err) {}

  const staticTexts = { ...globalLocales, ...componentLocales, ...(customLocaleData || {}) }

  let itemRendered = ''

  for (let index = 0; index < 2; index++) {
    if (data[index]) itemRendered += renderReviewsTeaserItem(data[index], data, index, staticTexts)
  }

  return itemRendered
}

function renderReviewsTeaserItem (review, data, index, staticTexts) {
  return `<div id="teaser-${review.id}" class="w-reviews-teaser--item" data-w-reviews-teaser--item="">
                <div class="w-reviews-teaser--rating">
                  ${review.rating
                    ? NumberScoreTemplate({
                      variant: 'bubbled',
                      scoreGrade: (review.rating.toFixed(1).substr(-1) !== '0' ? review.rating.toFixed(1) : review.rating.toFixed(0)),
                      scoreTitle: (review.score.scoreLabel ? review.score.scoreLabel : ''),
                      arrowDirection: '',
                      labelPosition: 'right'
                    })
                  : ''}
                </div>
                <div class="w-reviews-teaser--review-content" data-w-reviews-teaser--review-content>
                  ${CollapseTemplate({
                    id: `${review.id}-item-${index}`,
                    extraClasses: 'w-reviews-teaser__item-text after-fading-light',
                    collapseHeight: data.collapseHeight,
                    blockText: true,
                    attributes: { 'data-w-reviews-teaser--item': '' },
                    track: 'reviewsTeaser.showMore',
                    html: review.comment ? `<div class="m-body m-body--small">&ldquo;${review.comment}&rdquo;</div>` : `<span class="w-reviews-teaser__no-comment m-body m-body--small">${staticTexts.noComment}</span>`
                  })}
                  ${CollapseTriggerTemplate({
                    id: `${review.id}-item-${index}`,
                    text: staticTexts.readMore,
                    action: 'open',
                    extraClasses: 'w-reviews-teaser__item-trigger'
                  })}
                  ${CollapseTriggerTemplate({
                    id: `${review.id}-item-${index}`,
                    text: staticTexts.readLess,
                    action: 'close',
                    extraClasses: 'w-reviews-teaser__item-trigger'
                  })}
                </div>
                <div class="w-reviews-teaser--review-translate m-body m-body--small" data-w-reviews-teaser--review-translate></div>
                <div class="w-reviews-teaser--review-translate--btn-wrapper">
                  ${(languageSite !== review.languageCode.toLowerCase()) && review.comment && review.languageCode
                    ? `${BtnTemplate({
                        type: 'button',
                        variant: 'flat',
                        extraClasses: 'w-reviews-teaser--review-translate--btn',
                        attributes: { 'data-w-reviews-teaser--review-translate--btn': '' },
                        text: staticTexts.translateButton,
                        track: 'reviewsTeaser.translation'
                      })}
                      ${LoaderTemplate({ size: 'tiny', extraClasses: 'w-reviews-teaser__comment-translate-loader is-hidden' })}
                      ${BtnTemplate({
                        type: 'button',
                        variant: 'flat',
                        extraClasses: 'w-reviews-teaser--review-original--btn is-hidden',
                        attributes: { 'data-w-reviews-teaser--review-original--btn': '' },
                        text: staticTexts.viewOriginalButton,
                        track: 'reviewsTeaser.original'
                      })}`
                    : ''
                  }
                </div>
            </div>`
}
