export const da = {
  priceLegend: 'pris pr. person',
  publicPrice: 'offentlig pris',
  textButton: 'Vælg'
}

export const de = {
  priceLegend: 'Preis pro Person',
  publicPrice: 'wählen',
  textButton: 'select'
}

export const en = {
  priceLegend: 'price per person',
  publicPrice: 'public price',
  textButton: 'select'
}

export const nb = {
  priceLegend: 'pris per person',
  publicPrice: 'offentlig pris',
  textButton: 'å velge'
}
export const fr = {
  priceLegend: 'prix par personne',
  publicPrice: 'prix public',
  textButton: 'sélectionner'
}

export const nl = {
  priceLegend: 'prijs per persoon',
  publicPrice: 'openbare prijs',
  textButton: 'kiezen'
}

export const sv = {
  priceLegend: 'pris per person',
  publicPrice: 'offentligt pris',
  textButton: 'Välj'
}
