const pageTimerPrefix = 'session-time-'

/**
 * SessionRecorder stores in LocalStorage the time a user spends on a given page.
 *
 * The method retrievePairscollects all diferent LocalStorage entries created by all SessionRecorder instances and returns
 * a list of pairs with the accoId and the time spent on the page
 */
export default class SessionRecorder {
  constructor (accoId) {
    this.accoId = accoId
    this.shouldTimePage = true // This setting governs everything, so we need to disable this in case we dont want to track usage
    this.timerStartName = `session-start-${this.accoId}`
    this.timeSpentTimerName = `${pageTimerPrefix}${this.accoId}`
    this.addListeners()
  }

  toggleTimer () {
    if (!this.shouldTimePage) return
    if (document.hasFocus()) {
      this.startTimer()
    } else {
      this.stopTimer()
    }
  }

  startTimer () {
    localStorage.setItem(this.timerStartName, Date.now())
  }

  stopTimer () {
    if (!this.shouldTimePage) return

    const startTime = localStorage.getItem(this.timerStartName)
    if (!startTime) {
      this.clearListeners()
      return
    }

    const existingTimeSpentItem = localStorage.getItem(this.timeSpentTimerName)
    const existingTimeSpent = existingTimeSpentItem
      ? parseInt(existingTimeSpentItem, 10)
      : 0

    const elapsedTime = Date.now() - parseInt(startTime, 10) + existingTimeSpent
    localStorage.setItem(this.timeSpentTimerName, elapsedTime)
    localStorage.removeItem(this.timerStartName)

    this.clearListeners()
  }

  clearListeners () {
    window.removeEventListener('focus', this.stopTimer.bind(this))
    window.removeEventListener('blur', this.stopTimer.bind(this))
    window.removeEventListener('beforeunload', this.stopTimer.bind(this))
  }

  addListeners () {
    window.addEventListener('focus', this.toggleTimer.bind(this))
    window.addEventListener('blur', this.toggleTimer.bind(this))
    window.addEventListener('beforeunload', this.stopTimer.bind(this))
    this.toggleTimer()
  }

  retrievePairs () {
    const pageTimers = Object.keys(localStorage).filter((key) =>
      key.startsWith(pageTimerPrefix)
    )

    return pageTimers.map((key) => {
      const timeSpent = localStorage[key]
      return {
        accoId: this.getAccoIdFromKey(key),
        timeSpent: parseInt(timeSpent, 10)
      }
    })
  }

  getAccoIdFromKey (key) {
    return key.replace(pageTimerPrefix, '')
  }
}
