/**
 * The VoucherCheckBalanceInfo contains all data to render voucher information
 *
 * @typedef {Object}            SliderData
 *
 * @property {String}           expiredIcon         - Icon for the header when balance is expired
 * @property {String}           voucherCode         - Voucher code
 */
import { BoxTemplate } from '../../components/box/c-box.template'

export const defaultVoucherCheckBalanceInfoData = {
  expiredIcon: 'clock',
  voucherCode: '',
  refundModalId: '',
  refundButtonText: '',
  refundRequestAlreadySentText: '',
  canRequestRefund: false,
  isAlreadyRequestedAndPendingToBeRefunded: false,
  refundAlreadyRequestedAndPendingToBeRefundedText: '',
  isAlreadyRefunded: false,
  alreadyRefundedText: ''
}

export const VoucherCheckBalanceInfoTemplate = (d) => {
  d = { ...defaultVoucherCheckBalanceInfoData, ...d }
  return `${d.isExpired
    ? `<div class="w-voucher-check-balance__info">
        <span class="m-icon m-icon--size-huge m-icon--${d.expiredIcon}"></span>
          <div class="w-voucher-check-balance__expired-text">
            <span>${d.expiredText}</span>
            <span>${d.expiredExtraText}</span>
          </div>
      </div>`
    : `<div>
         <div data-w-voucher-check-balance__voucher data-w-voucher__code="${d.voucherCode}"></div>
       </div>`}`
}

export const RefundInfoTemplate = (d) => {
  return `${d.text
    ? BoxTemplate({
      variant: 'flat',
      extraClasses: `cs-state-${d.state} cs-state-${d.state}--light w-voucher-check-balance__refund-message`,
      html: d.text
    })
    : ''}`
}
