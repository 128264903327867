import { FormTemplate } from '../../components/form/c-form.template'
import { FormSectionTemplate } from '../../components/form/c-form__section.template'
import { FormFieldsetTemplate } from '../../components/form/c-form__fieldset.template'
import { ChoiceListTemplate } from '../../components/choice-list/c-choice-list.template'
import { BoxTemplate } from '../../components/box/c-box.template'
import { TitleTemplate } from '../../components/title/c-title.template'
import { DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'

export const RefundFormBankInfoTemplate = (d) => {
  return `
    <div>
      <p>${d.texts.text}</p>
      ${FormTemplate({
        hasSubmitButton: false,
        validate: true,
        track: d.track,
        attributes: {
          'data-w-refund__form': ''
        },
        extraClasses: 'w-refund__form',
        html: `    
          <div class="w-refund__form-body-section">
            ${FormSectionTemplate({
              html: `${FormFieldsetTemplate({
                html: `${DropdownTemplate({
                  id: `${d.id}type`,
                  label: d.texts.typeFieldLabel,
                  options: [{ value: d.sepaOption, text: d.texts.sepaFieldValue, selected: d.formData.type ? d.formData.type === d.sepaOption : true },
                            { value: d.nonSepaOption, text: d.texts.nonSepaFieldValue, selected: d.formData.type ? d.formData.type === d.nonSepaOption : false }],
                  attributes: {
                    'data-w-refund__type-choice-list': ''
                  },
                  required: true,
                  hideRequiredSymbol: true,
                  extraClasses: 'c-form__item c-form__item--medium'
                })}`
              })}
              ${d.fieldsets.map(fieldset => {
                return FormFieldsetTemplate({
                  extraClasses: 'w-refund__form-fieldset',
                  title: d.texts[`${fieldset.group}DetailsTitle`],
                  html: `${fieldset.fields.filter(f => f.types.includes(d.type)).map(field => field.fieldType === 'dropdown' && d.data[field.data] && d.data[field.data].length > 0
                    ? DropdownTemplate({
                      id: `${d.id}${field.name}`,
                      options: d.data[field.data].map(c => { return { value: c.id, text: c.name, selected: d.formData[field.name] === c.id, disabled: !!c.disabled } }),
                      label: d.texts[`${field.name}FieldLabel`],
                      placeholder: d.texts[`${field.name}FieldPlaceholder`],
                      required: field.isRequired.includes(d.type),
                      messageRequired: d.texts.isRequired,
                      attributes: {
                        'data-w-refund__dropdown': field.name
                      },
                      extraClasses: 'c-form__item c-form__item--medium'
                    })
                    : TextboxTemplate({
                      id: `${d.id}${field.name}`,
                      name: field.name,
                      value: d.formData[field.name] ? d.formData[field.name] : '',
                      label: d.texts[`${field.name}FieldLabel`],
                      placeholder: d.texts[`${field.name}FieldPlaceholder`],
                      icon: field.validationUrl && field.validationQueryKeyWord ? 'checkmark' : '',
                      iconPosition: field.validationUrl && field.validationQueryKeyWord ? 'right' : '',
                      required: field.isRequired.includes(d.type),
                      messageRequired: d.texts.isRequired,
                      attributes: {
                        'data-w-refund__textbox': field.name,
                        ...(d.texts[`${field.name}FieldValidationUrl`] && field.validationUrl) && { 'data-w-refund__validate-url': d.texts[`${field.name}FieldValidationUrl`] },
                        'data-w-refund__field-name': field.name
                      },
                      patternRule: !d.texts[`${field.name}FieldValidationUrl`] && d.texts[`${field.name}FieldPattern`],
                      messageInvalid: d.texts[`${field.name}FieldMessagePattern`],
                      extraClasses: `c-form__item c-form__item--${field.size}`
                    })).join('')}`
                })
              }).join('')}
              `
            })}
          </div>`
      })}
      </div>
    `
}

export const RefundFormRemarksAndConditionsTemplate = (d) => {
  return `
    <div>
      ${TitleTemplate({
        text: d.texts.filledData
      })}
      ${BoxTemplate({
        variant: 'flat',
        extraClasses: 'cs-state-info cs-state-info--light w-refund__filled-data-box',
        html: `<ul>${Object.entries(d.formData).map(p => `<li>${p[1]}</li>`).join('')}</ul>`
      })}
      ${FormTemplate({
        hasSubmitButton: false,
        validate: true,
        track: d.track,
        attributes: {
          'data-w-refund__form': ''
        },
        extraClasses: 'w-refund__form',
        html: `    
          <div class="w-refund__form-body-section">
            ${FormSectionTemplate({
              html: FormFieldsetTemplate({
                html: `
                  <div class="w-refund__terms-conditions">${d.texts.termsAndConditionsText}</div>
                  ${ChoiceListTemplate({
                    id: `${d.id}conditions`,
                    name: 'conditions',
                    method: 'multiple',
                    required: true,
                    items: [{ text: d.texts.conditionsLabel, value: 'accept', required: true, size: 'small' }],
                    attributes: {
                      'data-w-refund__conditions': '',
                      'data-message-required': d.texts.conditionsRequiredMessage
                    },
                    extraClasses: 'c-form__item c-form__item--large'
                  })}
                  `
              })
            })}
          </div>`
      })}
    </div>
    `
}
