/**
 * The SkiPricesTemplate contains all data needed to hydrate a CarRental Item
 *
 * @typedef {Object}            SkiPricesItemTemplate
 *
 * @property {String}           tableHeaders                                   - Header texts for the ski prices tables
 *
 */

import { TableTemplate } from '../../components/table/c-table.template'
import { TableItemTemplate } from '../../components/table/c-table__item.template'
import { AccordionTemplate, defaultAccordionData } from '../../components/accordion/c-accordion.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'

export const defaultSkiPricesData = {
  tableHeaders: []
}

export const SkiPricesTemplate = (data, skiPriceSection) => {
  data = {
    ...defaultSkiPricesData,
    ...data
  }

  return `
  <div class="w-ski-prices__section sp-stack--medium">
    ${skiPriceSection.titleSection ? TitleTemplate(Object.assign({}, defaultTitleData, { size: 'medium', text: skiPriceSection.titleSection, extraClasses: 'sp-stack--medium' })) : ''}
    ${AccordionTemplate(Object.assign({}, defaultAccordionData, {
      items: skiPriceSection.skiPriceGroups.map((skiPriceGroup, index) => {
        return {
          title: skiPriceGroup.titleGroup,
          innerHTML: SkiPriceDetailsGroupsTemplate(data, skiPriceGroup.skiPriceDetailsGroups),
          id: `${data.id}-ski-prices-group-${data.sectionCount}-${index}`
        }
      }),
      id: `${data.id}-ski-prices-group-${data.sectionCount}`
    }))}
  </div>
  `
}

const SkiPriceDetailsGroupsTemplate = (data, skiPriceDetailsGroups) => {
  return `${TableTemplate({
    variant: 'stripped',
    extraClasses: 'w-ski-prices__item-table m-body m-body--small',
    innerHTML: `
      ${TableItemTemplate({
        extraClasses: 'w-ski-prices__item-row',
        innerHTML: `
            <div class="w-ski-prices__item-cell">${data.tableHeaders[0] !== undefined ? data.tableHeaders[0] : ''}</div>
            <div class="w-ski-prices__item-cell">${data.tableHeaders[1] !== undefined ? data.tableHeaders[1] : ''}</div>
            <div class="w-ski-prices__item-cell">${data.tableHeaders[2] !== undefined ? data.tableHeaders[2] : ''}</div>
          `
      })}

      ${skiPriceDetailsGroups.map(skiPriceDetailsGroup => {
        return skiPriceDetailsGroup.details.map((detail, index) => {
          return `
           ${TableItemTemplate({
             extraClasses: 'w-car-rental__item-row',
             innerHTML: `
            <div class="w-ski-prices__item-cell w-ski-prices__item-cell--body">${skiPriceDetailsGroup.description}</div>
            <div class="w-ski-prices__item-cell w-ski-prices__item-cell--body">${detail.durationText}</div>
            <div class="w-ski-prices__item-cell w-ski-prices__item-cell--body">${detail.priceText}</div>
          `
           })}
        `
        }).join('')
      }).join('')}
    `
  })}
  `
}
