/**
 * The ReviewsListItemTemplate contains all data needed to hydrate a Reviews List Item
 *
 * @typedef {Object}            ReviewsListItemTemplate
 *
 * @property {String}           id                            - ReviewListItem id
 * @property {String}           departureDate                 - ReviewListItem departure date entered when did the review
 * @property {String}           userFullName                  - ReviewListItem user complete name entered when did the review
 * @property {String}           partyCompositionId            - ReviewListItem party composition id selected by the user when did the review
 * @property {String}           comment                       - ReviewListItem comment entered by the user
 *
 */

import { getTokenClass } from '../../../js/helpers/tokens'
import { CollapseTriggerTemplate } from '../../components/collapse/c-collapse__trigger.template'
import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { NumberScoreTemplate } from '../../components/number-score/c-number-score.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { LoaderTemplate } from '../../components/loader/c-loader.template'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'

const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.reviewsList`)
const languageSite = language

// Import tokens
const tokensShared = require('./data/w-reviews-list__tokens.json')['w-reviews-list']

const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/reviews-list/data/w-reviews-list__tokens.json`)['w-reviews-list'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultReviewsListItemData = {
  id: '',
  departureDate: '',
  userFullName: '',
  partyCompositionId: '',
  comment: '',
  collapseHeight: 140,
  countryCode: '',
  languageCode: ''
}

export const ReviewsListItemTemplate = (data, reviewsListId, showScores, showPartyComposition) => {
  data = { ...defaultReviewsListItemData, ...data }

  const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${reviewsListId}"]`)
  let customLocaleData = null
  try {
    customLocaleData = JSON.parse(customLocaleElement.textContent)
  } catch (err) {}

  const staticTexts = { ...globalLocales, ...componentLocales, ...(customLocaleData || {}) }

  return `
    <div id="${data.id}" 
      class="c-box o-box w-reviews-list__item 
      ${showScores ? getTokenClass('item', 'default', tokens) : 'w-reviews-list__item--no-scores'}"
      ${data.languageCode ? 'data-review-language=' + data.languageCode : ''}
      ${data.countryCode ? 'data-review-country=' + data.countryCode : ''}
      data-w-reviews-list__item>
      <div class="w-reviews-list__main-rating" data-w-reviews-list__main-rating>
      ${data.rating
        ? NumberScoreTemplate({
          variant: 'bubbled',
          scoreGrade: (data.rating.toFixed(1).substr(-1) !== '0' ? data.rating.toFixed(1) : data.rating.toFixed(0)),
          scoreTitle: (data.score.scoreLabel ? data.score.scoreLabel : ''),
          scoreLabel: '',
          scoreBasis: '',
          arrowDirection: '',
          smallBasis: true,
          extraClasses: 'w-reviews-list__score',
          labelPosition: 'right'
        })
      : ''}
        <div class="w-reviews-list__item-title ${getTokenClass('item-title__material', 'default', tokens)}">${data.userFullName ? data.userFullName : staticTexts.anonymous}</div>
        <div class="w-reviews-list__item-date m-body ${getTokenClass('item-date__material', 'default', tokens)}">${staticTexts.stayedIn} ${data.departureDate}</div>
        <div class ="w-reviews-list__item-flag-partycomp">
          ${data.languageCode ? `<span class="m-country-flag w-reviews-list__country-flag" data-w-reviews-list__country-flag data-m-country-flag__country-id="${data.countryCode.toLowerCase()}"></span>` : ''}
          ${showPartyComposition && data.partyCompositionId ? `<div class="w-reviews-list__item-subtitle c-label c-label--badge m-body ${getTokenClass('item-subtitle__material', 'default', tokens)}" data-w-reviews-list__item-subtitle>${staticTexts[data.partyCompositionId.toLowerCase()]}</div>` : ''}
        </div>
        </div>
      <div class="w-reviews-list__comment">
        <div class="w-reviews-list__comment-content">
          ${CollapseTemplate({
            id: `${data.id}__collapse`,
            extraClasses: 'w-reviews-list__item-text after-fading-light',
            attributes: { 'data-c-collapse__block-text': true, 'data-c-collapse__collapse-height': data.collapseHeight, 'data-w-reviews-list__item-text': '' },
            html: data.comment ? `<div class="m-body m-body--small">&ldquo;${data.comment}&rdquo;</div>` : `<span class="w-reviews-list__no-comment m-body m-body--small">${staticTexts.noComment}</span>`
          })}
          ${CollapseTriggerTemplate({
            id: `${data.id}__collapse`,
            text: staticTexts.readMore,
            action: 'open',
            extraClasses: 'w-reviews-list__item-trigger'
          })}
          ${CollapseTriggerTemplate({
            id: `${data.id}__collapse`,
            text: staticTexts.readLess,
            action: 'close',
            extraClasses: 'w-reviews-list__item-trigger'
          })}
          ${(languageSite !== data.languageCode.toLowerCase()) && data.comment && data.languageCode
            ? `<div class="w-reviews-list__comment-translate-area">
              <div class="w-reviews-list__comment-translate-text empty" data-w-reviews-list__comment-translate-text></div>
              ${BtnTemplate({
                type: 'button',
                variant: 'flat',
                extraClasses: 'w-reviews-list__comment-translated-button w-reviews-list__comment-translated-button-translate',
                attributes: { 'data-w-reviews-list__comment-translated-button-translate': '' },
                text: staticTexts.translateButton
              })}
              ${LoaderTemplate({ size: 'tiny', extraClasses: 'w-reviews-list__comment-translate-loader is-hidden' })}
              ${BtnTemplate({
                type: 'button',
                variant: 'flat',
                extraClasses: 'w-reviews-list__comment-translated-button is-hidden w-reviews-list__comment-translated-button-view-original',
                attributes: { 'data-w-reviews-list__comment-translated-button-view-original': '' },
                text: staticTexts.viewOriginalButton
              })}
            </div>`
            : ''
          }
        </div>
      </div>
      <div class="w-reviews-list__score-points">
      ${showScores && data.categories
        ? `<div class="w-reviews-list__item-scores">
          ${data.categories.map((dataBar) => {
            return NumberScoreTemplate({
              variant: 'bubbled',
              scoreGrade: dataBar.rating.toString(),
              scoreTitle: dataBar.name ? staticTexts[dataBar.name.toLowerCase()] : '',
              scoreLabel: '',
              scoreBasis: '',
              arrowDirection: '',
              smallBasis: true,
              extraClasses: 'w-reviews-list__item-score',
              labelPosition: 'right'
            })
          }).join('')}
          </div>
      `
        : ''}
      </div>
    </div>
   `
}
