export const da = {
  stayedIn: 'Opholdt sig i',
  anonymous: 'anonym',
  noComment: 'Brugeren har ikke efterladt nogen kommentarer',
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Par',
  family: 'Familie',
  friends: 'Venner',
  other: 'Andet',

  // Extended Party Compositions
  alone: 'Alene',
  withfriends: 'Med venner',
  withpartner: 'Med partner',
  withfamily: 'Familie',
  withsingleparent: 'Med enlig forælder',
  group: 'Gruppe',

  // Review Categories
  holiday: 'Ferie',
  pricequality: 'Pris Kvalitet',
  location: 'Beliggenhed',
  hotel: 'Hotel',
  cleanliness: 'Renhed',
  personnel: 'Personale',
  children: 'Børn',
  meal: 'Måltid',
  pool: 'Pool',
  facilities: 'Faciliteter'
}

export const de = {
  stayedIn: 'Waren im jahr',
  anonymous: 'anonym',
  noComment: 'Der Benutzer hat keine Kommentare hinterlassen',
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Paar',
  family: 'Familie',
  friends: 'Freunde',
  other: 'Sonstiges',

  // Extended Party Compositions
  alone: 'Allein',
  withfriends: 'Mit Freunden',
  withpartner: 'Mit Partner',
  withfamily: 'Familie',
  withsingleparent: 'Mit Alleinerziehenden',
  group: 'Gruppe',

  // Review Categories
  holiday: 'Urlaub',
  pricequality: 'Preis Qualität',
  location: 'Preis Qualität',
  hotel: 'Hotel',
  cleanliness: 'Sauberkeit',
  personnel: 'Personal',
  children: 'Kinder',
  meal: 'Mahlzeit',
  pool: 'Pool',
  facilities: 'Einrichtungen'
}

export const en = {
  stayedIn: 'Stayed in',
  anonymous: 'anonymous',
  noComment: 'The user has left no comments',
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Couple',
  family: 'Family',
  friends: 'Friends',
  other: 'Other',

  // Extended Party Compositions
  alone: 'Alone',
  withfriends: 'With Friends',
  withpartner: 'With Partner',
  withfamily: 'Family',
  withsingleparent: 'With Single Parent',
  group: 'Group',

  // Review Categories
  holiday: 'Holiday',
  pricequality: 'Price Quality',
  location: 'Location',
  hotel: 'Hotel',
  cleanliness: 'Cleanliness',
  personnel: 'Personnel',
  children: 'Children',
  meal: 'Meal',
  pool: 'Pool',
  facilities: 'Facilities'
}
export const nb = {
  stayedIn: 'Ble hjemme',
  anonymous: 'anonym',
  noComment: 'Brukeren har ikke lagt igjen kommentarer',
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Par',
  family: 'Familie',
  friends: 'venner',
  other: 'Annen',

  // Extended Party Compositions
  alone: 'Alene',
  withfriends: 'Med Venner',
  withpartner: 'Med Partner',
  withfamily: 'Med Familie',
  withsingleparent: 'Med Enslig Forsørger',
  group: 'Gruppe',

  // Review Categories
  holiday: 'Ferie',
  pricequality: 'Priskvalitet',
  location: 'plassering',
  hotel: 'Hotell',
  cleanliness: 'Renslighet',
  personnel: 'Staff',
  children: 'Barn',
  meal: 'Måltid',
  pool: 'Basseng',
  facilities: 'fasiliteter'
}

export const fr = {
  stayedIn: "J'ai séjourné en",
  anonymous: 'anonyme',
  noComment: "L'utilisateur n'a laissé aucun commentaire",
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Couple',
  family: 'Familie',
  friends: 'Amis',
  other: 'Autre',

  // Extended Party Compositions
  alone: 'Alone',
  withfriends: 'Avec Des Amis',
  withpartner: 'Avec Partenairer',
  withfamily: 'Famille',
  withsingleparent: 'Avec Un Parent Seul',
  group: 'Grouper',

  // Review Categories
  holiday: 'Jour férié',
  pricequality: 'Prix Qualité',
  location: 'Lieu',
  hotel: 'Hôtel',
  cleanliness: 'Propreté',
  personnel: 'Personnel',
  children: 'Enfants',
  meal: 'Repas',
  pool: 'Piscine',
  facilities: 'Installations'
}

export const nl = {
  stayedIn: 'Verbleven in',
  anonymous: 'anoniem',
  noComment: 'De gebruiker heeft geen opmerkingen achtergelaten',
  translateButton: 'Vertaal deze tekst',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Paar',
  family: 'Familie',
  friends: 'Vrienden',
  other: 'Andere',

  // Extended Party Compositions
  alone: 'Alleen',
  withfriends: 'Vrienden',
  withpartner: 'Met partner',
  withfamily: 'Familie',
  withsingleparent: 'Alleenstaande Ouder',
  group: 'groep',

  // Review Categories
  holiday: 'Vakantie',
  pricequality: 'Prijs kwaliteit',
  location: 'Plaats',
  hotel: 'Hotel',
  cleanliness: 'Zuiverheid',
  personnel: 'Personeel',
  children: 'Kinderen',
  meal: 'Maaltijd',
  pool: 'Zwembad',
  facilities: 'Faciliteiten'
}

export const sv = {
  stayedIn: 'Stannade i',
  anonymous: 'anonym',
  noComment: 'Användaren har inte lämnat några kommentarer',
  translateButton: 'Translate this text',
  viewOriginalButton: 'View original text',

  // Party composition
  couple: 'Par',
  family: 'Familj',
  friends: 'Vänner',
  other: 'Andra',

  // Extended Party Compositions
  alone: 'Ensam',
  withfriends: 'Med vänner',
  withpartner: 'Med Partner',
  withfamily: 'Familj',
  withsingleparent: 'Med Ensamstående förälder',
  group: 'Grupp',

  // Review Categories
  holiday: 'Semester',
  pricequality: 'Priskvalitet',
  location: 'Plats',
  hotel: 'Hotell',
  cleanliness: 'Renlighet',
  personnel: 'Personal',
  children: 'Barn',
  meal: 'Måltid',
  pool: 'Slå samman',
  facilities: 'Faciliteter'
}
