import { encodeAttributes } from '../../../js/helpers/string'
import { ModalV2Template } from '../../components/modal-v2/c-modal-v2.template'

export const defaultRefundTemplate = {
  id: '',
  extraClasses: '',
  refundRequestUrl: '',
  showAllCountries: false,
  modalId: '',
  attributes: null,
  track: null
}

export const RefundTemplate = (d) => {
  d = { ...defaultRefundTemplate, ...d }
  return `
    <div id="${d.id}"
        class="w=refund ${d.extraClasses}"
        data-w-refund__refund-request-url="${d.refundRequestUrl}"
        ${d.showAllCountries ? 'data-w-refund__show-all-countries=""' : ''}
        data-w-refund__modal-id="${d.modalId}"
        data-js-widget="w-refund"
        ${d.track ? `data-track="${d.track}"` : ''}
        ${d.attributes ? encodeAttributes(d.attributes) : ''}>
      ${ModalV2Template({
        size: 'medium',
        id: d.modalId,
        async: false,
        extraClasses: 'w-refund__modal',
        attributes: { 'data-w-refund__modal': '' },
        closable: true,
        style: 'neutral',
        bodyContent: '<div data-w-refund__modal-container></div>'
      })}
    </div>
    `
}
