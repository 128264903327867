import { registerWidget } from '../../../js/core/widget/widget-directory'
import registeredEvents from '../../../js/helpers/registered-events'
import { staticContentDataEvents } from '../../../js/document/event-types'
const EventEmitter = require('eventemitter3')

const widgetApi = 'w-static-content-data'

const widgetQueries = {
  trackAttr: 'data-track'
}

export default class StaticContentData {
  /**
   * Creates a new StaticContentData
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element.
   *
   */
  constructor (element) {
    this.element = element

    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    const dataset = Object.assign({}, this.element.dataset)

    const queryString = window.location.search
    const urlParams = new window.URLSearchParams(queryString)
    const originList = urlParams.get('originList') || 'acco-page'

    this.events.emit(staticContentDataEvents.STATIC_CONTENT_DATA_READY, { dataset, originList })
  }
}

registerWidget(StaticContentData, widgetApi)
